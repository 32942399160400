import React, { useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash-es'
import { Grid, Col, Row } from 'react-bootstrap'
import { bindActionCreators } from 'redux'

import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import RecordSection from 'syft-acp-core/components/RecordSection'
import { Button } from 'syft-acp-atoms/Button'
import AddAgencyEmployerModal from 'syft-acp-core/components/Modal/AddAgencyEmployerModal'
import entityDetailList from 'syft-acp-core/components/EntityDetail/entityDetailList'
import * as filterTypes from 'syft-acp-util/components/FilterForm/FilterForm'
import * as agencyProfile from 'syft-acp-core/actions/agency-profile'
import entityDetail from 'syft-acp-util/entityDetail'
import { store } from 'syft-acp-core/store'
import { showModal } from 'syft-acp-core/store/modals/actions'

import entityList from 'syft-acp-util/entityList'
import { agencyStructure } from './structure'
import { AgencyViewControls } from './AgencyViewControls'

import { tableFormat } from './structure'

const AddEmployerButton = memo(({ label }) => (
  <Button kind="primary" onClick={() => store.dispatch(showModal('addAgencyEmployerModal'))}>
    {label}
  </Button>
))

AddEmployerButton.propTypes = {
  label: PropTypes.string.isRequired,
}

const AgencyViewTableWrapper = entityDetailList({ title: 'Employers' })

const actionFormat = [
  {
    label: 'Add Employer',
    type: filterTypes.TYPE_CUSTOM_BUTTON,
    customButton: AddEmployerButton,
  },
]

const agencyViewTable = (data, newAgencyPlatformRelationship) =>
  entityList(
    AgencyViewTableWrapper,
    tableFormat(data),
    null,
    newAgencyPlatformRelationship ? actionFormat : [],
    'workerLearningPlanStatus',
    {},
    {
      entityList: get(data, 'employers', []),
      recordList: true,
      disableRoutingQuery: true,
      hasLinks: false,
    },
  )

const AgencyView = memo(
  ({ data, initialData, actionUpdate, actions, params, isPristine }) => {
    const newAgencyPlatformRelationship = useFlexFlagIsOn('pte_16679_new_agency_platform_relationship')

    useEffect(() => {
      actions.fetchAgencyProfile({ agencyId: params.id })
    }, [params.id, actions])
    const viewData = {
      ...initialData,
      ...data,
    }
    const AgencyViewTableContainer = agencyViewTable(viewData, newAgencyPlatformRelationship)
    return (
      <SiteComponent>
        <Grid className="entity-list-wrapper-outer type-regular container">
          <Row>
            <Col md={12} sm={12}>
              <AgencyViewControls
                saveAction={actions.saveAgencyProfile}
                hasControlButtons
                data={viewData}
                isPristine={isPristine}
              />
              <RecordSection
                title="Agency"
                data={viewData}
                structure={agencyStructure}
                update={actionUpdate}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12}>
              <AgencyViewTableContainer viewData={viewData} />
            </Col>
          </Row>
        </Grid>
        <AddAgencyEmployerModal data={viewData} />
      </SiteComponent>
    )
  },
  (prevProps, nextProps) => {
    return (
      get(prevProps, 'initialData.id') === get(nextProps, 'initialData.id') &&
      get(prevProps, 'initialData.employers.length') === get(nextProps, 'initialData.employers.length') &&
      get(prevProps, 'data.name') === get(nextProps, 'data.name') &&
      get(prevProps, 'data.email') === get(nextProps, 'data.email') &&
      get(prevProps, 'data.telephone_number') === get(nextProps, 'data.telephone_number')
    )
  },
)

AgencyView.propTypes = {
  data: PropTypes.object,
  initialData: PropTypes.object.isRequired,
  params: PropTypes.object,
  newAgencyPlatformRelationship: PropTypes.bool.isRequired,
  actionUpdate: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  isPristine: PropTypes.bool.isRequired,
}

AgencyView.defaultProps = {
  data: {},
  params: {},
}

export default entityDetail(
  'agencyProfile',
  connect(
    (state, props) => {
      return {
        initialData: get(state, `agencyProfile.entityDetail[${props.params.id}]`),
      }
    },
    dispatch => ({
      actions: bindActionCreators({ ...agencyProfile }, dispatch),
    }),
  )(AgencyView),
)

import React, { useCallback, useEffect, useState } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import Acp from 'syft-acp-uikit'
import { flowRight, isEmpty } from 'lodash-es'
import { useTrackingTrigger, TrackingTrigger } from '@indeed/flex-tracking-context'

import deepEqual from 'react-fast-compare'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import { fetchAgencyShiftHistory } from 'syft-acp-core/store/agency-shift-history/actions'
import { AgencyShiftHistoryEntity } from 'syft-acp-core/store/agency-shift-history/types'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'
import { trackingEvents } from './tracking'

export const entityConnector = createEntityListConnector<Partial<AgencyShiftHistoryEntity>>({
  entityActions: {
    fetchEntities: fetchAgencyShiftHistory,
  },
  entityStore: 'agencyShiftHistory',
})

export const storeConnector = connect(null, (_, props: ConnectedProps<typeof entityConnector>) => ({
  actions: {
    ...props.actions,
  },
}))

export type AllProps = ConnectedProps<typeof entityConnector> & ConnectedProps<typeof storeConnector>

export const AgencyShiftHistoryList = ({ data, query, actions }: AllProps) => {
  const prevQuery = usePrevValue(query)
  const defaultMessage = 'Add shift ID or jobID to load results'
  const triggerEvent = useTrackingTrigger()
  const [notFoundMessage, setNotFoundMessage] = useState(defaultMessage)
  const fetch = useCallback(
    (q: Record<string, any>) => actions.fetchEntities && actions.fetchEntities({ options: q }),
    [actions],
  )

  useEffect(() => {
    if (!deepEqual(prevQuery, query) && !isEmpty(query)) {
      fetch(query)
    }
  }, [fetch, prevQuery, query])

  useEffect(() => {
    const messageOneParam = !query.shift_id && query.job_id ? 'No history for entered job' : defaultMessage
    const message = query.shift_id && !query.job_id ? 'No history for entered shift' : messageOneParam
    setNotFoundMessage(message)
  }, [query])

  const onFilterChange = useCallback(
    filter => {
      triggerEvent(trackingEvents.AGENCY_SHIFT_HISTORY.FILTERS.CHANGED, { filter })
    },
    [triggerEvent],
  )

  return (
    <>
      <Acp.EntityList hasResultCount hasPagination idKeyValue="id" data={{ data: Object.values(data.data) }}>
        <Acp.Actions narrowContainer>
          <Acp.FilterGroup title="Shift ID:">
            <Acp.Filter.Text onChange={onFilterChange} name="shift_id" placeholder="Shift ID" />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Job ID:">
            <Acp.Filter.Text onChange={onFilterChange} name="job_id" placeholder="Job ID" />
          </Acp.FilterGroup>
        </Acp.Actions>
        <Acp.Table notFoundMessage={notFoundMessage}>
          <Acp.Col.Text
            value="shift_id"
            colLink={(row: AgencyShiftHistoryEntity) => `/listings/view/${row.listing_id}`}
            header="Shift ID"
          />
          <Acp.Col.Text value="agency_shift_id" header="Agency shift ID" />
          <Acp.Col.Text value="actor_email" header="Actor email" />
          <Acp.Col.Text value="actor_type" header="Actor type" />
          <Acp.Col.Text value="event" header="Event" />
          <Acp.Col.Text value="agency_name" header="Agency name" />
          <Acp.Col.Text value="agency_rank" header="Agency rank" />
          <Acp.Col.Timestamp value="timestamp" options={{ showTimezone: true }} header="Timestamp" />
          <Acp.Col.Text value="spots_from" header="Spots from" />
          <Acp.Col.Text value="spots_to" header="Spots to" />
        </Acp.Table>
      </Acp.EntityList>
      <TrackingTrigger
        onLoad={{
          event: trackingEvents.AGENCY_SHIFT_HISTORY.PAGE.LOADED,
        }}
      />
    </>
  )
}

export default flowRight(entityConnector, storeConnector)(AgencyShiftHistoryList)

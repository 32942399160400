import * as structureTypes from 'syft-acp-core/components/EditableTable'
import { AppealEntity } from 'syft-acp-core/store/types'

export const dataStructure = (isNew = false, isClosed = false, country: 'US' | 'UK') => [
  {
    title: 'Appeal information',
    structure: [
      [
        'created_at',
        {
          header: 'Appeal raised',
          type: structureTypes.TYPE_DATE,
        },
      ],
      [
        'appeal_reason.id',
        {
          header: 'Appeal reason',
          editable: isNew,
          type: structureTypes.TYPE_APPEAL_REASON,
        },
      ],
      [
        'appeal_text',
        {
          header: 'Appeal text',
          editable: isNew,
        },
      ],
      [
        'salesforce_case_url',
        {
          header: 'Salesforce URL',
          editable: isNew,
        },
      ],
      [
        'notes',
        {
          header: 'Notes',
          editable: !isClosed,
        },
      ],
      [
        'outcome',
        {
          header: 'Outcome',
          editable: !isNew && !isClosed,
          type: structureTypes.TYPE_APPEAL_OUTCOME,
        },
      ],
    ],
  },
  {
    title: 'Worker information',
    structure: [
      [
        'worker.id',
        {
          header: 'Worker ID',
          type: structureTypes.TYPE_LINK,
          parameters: {
            to: ({ worker }: Partial<AppealEntity>) => worker && `/entity/workers/view/${worker.id}`,
          },
        },
      ],
      [
        'worker.full_name',
        {
          header: 'Worker name',
        },
      ],
      [
        'worker.email',
        {
          header: 'Appeals',
          type: structureTypes.TYPE_LINK,
          parameters: {
            to: ({ worker }: Partial<AppealEntity>) =>
              worker && `/conduct/appeals/list?email=${worker.email}`,
          },
        },
      ],
      [
        'worker.id',
        {
          header: 'Flex Levels',
          type: structureTypes.TYPE_LINK,
          parameters: {
            external: true,
            to: ({ worker }: Partial<AppealEntity>) =>
              worker &&
              `https://flex-redash.indeed.tech/queries/24494?p_Worker%20ID=$${worker?.id}${country}`,
          },
          calc: () => {
            return 'Details'
          },
        },
      ],
    ].filter(Boolean),
  },
  {
    title: 'Negative action information',
    structure: [
      [
        'strike.strike_reason.display_name',
        {
          header: 'Negative action name',
        },
      ],
      [
        'strike.id',
        {
          header: 'Negative action ID',
          type: structureTypes.TYPE_LINK,
          parameters: {
            to: ({ worker, strike }: Partial<AppealEntity>) =>
              worker && `/entity/workers/view/${worker.id}/strikes/view/${strike?.id}`,
          },
        },
      ],
      [
        'strike.activated_at',
        {
          header: 'Negative action date',
          type: structureTypes.TYPE_DATE,
        },
      ],
    ],
  },
]

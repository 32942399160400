import React, { useEffect, useRef } from 'react'
import { capitalize, words } from 'lodash-es'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import RecordSection from 'syft-acp-core/components/RecordSection'
import { Banner } from '@indeed/ifl-components'

import { entityDetailHOC } from 'syft-acp-util/entityDetail'

import { preferencesStructure } from './PreferenceDetail.structure'
import type { PreferenceDetailProps } from './PreferenceDetail.types'

const PreferenceDetail = ({
  root,
  onEntityUpdate,
  data,
  entityMap,
  entityDetail,
  isPristine,
  id,
  actionUpdate,
  hideSectionTitle,
}: PreferenceDetailProps) => {
  const enableOverbookPreferences = useFlexFlagIsOn('overbooked_preferences')
  const enableCascadeClientPreference = useFlexFlagIsOn('pte_34168_cascade_client_preference')
  const isMountedRef = useRef(false)
  const structure = preferencesStructure(entityDetail[id] || entityMap[id], root, enableOverbookPreferences)[
    data.group as keyof ReturnType<typeof preferencesStructure>
  ]

  const showNotice =
    enableCascadeClientPreference &&
    data?.group === 'rates' &&
    data?.job_id === null &&
    data?.role_id === null
  const noticeMessage =
    data?.work_location_id === null
      ? 'Head office preferences will automatically apply to all venues and upcoming jobs.'
      : 'Venue preferences will automatically apply to all upcoming jobs.'
  const notice = <Banner status="info">{noticeMessage}</Banner>

  useEffect(() => {
    if (isMountedRef.current) {
      onEntityUpdate({ value: data, isPristine })
    } else {
      isMountedRef.current = true
    }
    // eslint-disable-next-line
  }, [data, id, isPristine])

  return (
    <RecordSection
      title={hideSectionTitle ? '' : capitalize(words(data.group).join(' '))}
      data={data}
      notificationsNode={showNotice && notice}
      structure={structure}
      update={actionUpdate}
    />
  )
}

export const entityDetailConnector = entityDetailHOC('employerPreferences')

export default entityDetailConnector(PreferenceDetail)

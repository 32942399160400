import * as structureTypes from 'syft-acp-core/components/EditableTable'
import { WorkerStrikeEntity } from 'syft-acp-core/store/types'

type StructureType = (
  | string
  | {
      header: string
      type?: symbol
      editable?: boolean | ((data: WorkerStrikeEntity) => boolean)
      testId?: string
    }
)[][]

export const getDataStructure = (isNew = false) => {
  const structure: StructureType = [
    [
      'id',
      {
        header: 'ID',
      },
    ],
    [
      'shift_id',
      {
        header: 'Shift ID',
        editable: true,
      },
    ],
    [
      'shift.start_time',
      {
        header: 'Shift start time',
        type: structureTypes.TYPE_TIMESTAMP,
      },
    ],
    [
      'reason',
      {
        header: 'Reason',
        editable: true,
        type: structureTypes.TYPE_STRIKES_DROPDOWN,
      },
    ],
    [
      'internal_notes',
      {
        header: 'Internal notes',
        editable: true,
      },
    ],
  ]
  if (!isNew) {
    structure.push(
      [
        'worker_rejection_reason.code',
        {
          header: 'Rejection Reason',
          type: structureTypes.TYPE_STRIKES_DROPDOWN,
        },
      ],

      [
        'email_sent_at',
        {
          header: 'Email sent at',
          type: structureTypes.TYPE_TIMESTAMP,
        },
      ],
      [
        'activated_at',
        {
          header: 'Activated at',
          type: structureTypes.TYPE_TIMESTAMP,
        },
      ],
      [
        'activator.email',
        {
          header: 'Activated by',
        },
      ],
      [
        'deactivated_at',
        {
          header: 'Deactivated at',
          type: structureTypes.TYPE_TIMESTAMP,
        },
      ],
      [
        'activate',
        {
          header: 'Activate',
          type: structureTypes.TYPE_CHECKBOX,
          editable: ({ deactivated_at }: WorkerStrikeEntity) => !deactivated_at,
          testId: 'worker-strike-activatable',
        },
      ],
      [
        'send_email',
        {
          header: 'Send email',
          type: structureTypes.TYPE_CHECKBOX,
          editable: true,
        },
      ],
    )
  }

  return structure
}

export const SAVE_BUTTON_EVENT = 'generic.worker_strike_details_controls.save_button.clicked'

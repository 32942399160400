import * as types from '../action-types'

type CreateAgencyPlatformProps = {
  agencyID: number
  platformID: number
}
export const createAgencyPlatform = ({ agencyID, platformID }: CreateAgencyPlatformProps) => ({
  type: types.CREATE_AGENCY_PLATFORM_POST_BEGIN,
  payload: { agency_id: agencyID, platform_id: platformID },
})

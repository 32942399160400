import React from 'react'
import DataDatetime from 'syft-acp-core/components/EditableTable/DataDatetime'
import * as structureTypes from 'syft-acp-core/components/EditableTable'
import { Information as InformationIcon } from '@indeed/ifl-icons'
import { noop } from 'lodash-es'
import { Box, Tooltip } from '@indeed/ifl-components'

import { Data } from './types'

export const EligibleWorkers = ({
  count,
  lastUpdate,
  timezone,
}: {
  count: number
  lastUpdate: string
  timezone: string
}) => {
  return (
    <Box as="div" sx={{ display: 'flex' }}>
      <Box as="div">{`${count} worker${count === 1 ? '' : 's'} eligible (Last update`}</Box>
      <Box as="div" sx={{ marginLeft: '-8px', marginTop: '-8px' }}>
        <DataDatetime parameters={{ timeZone: timezone }} value={lastUpdate} data={{}} onChange={noop} />
      </Box>
      <Box as="div">{' )'}</Box>
    </Box>
  )
}

export const structure = [
  [
    'offering_stats.workers_offered',
    {
      header: 'Workers offered',
      defaultValue: '—',
    },
  ],
  [
    'select_offer_type',
    {
      header: (
        <>
          Selected offer type{' '}
          <Tooltip
            label="This section reflects the stats for the latest offer type selected."
            placement="bottom"
            arrow
          >
            <InformationIcon size="sm" sx={{ verticalAlign: 'top' }} color="grey" />
          </Tooltip>
        </>
      ),
    },
  ],
  [
    'offering_stats.last_time_it_run',
    {
      header: <div style={{ marginLeft: 10 }}>Job candidate pool size</div>,
      calc: (data: Data) => {
        if (
          (data?.offering_stats?.workers_available || data?.offering_stats?.workers_available === 0) &&
          data?.offering_stats?.workers_available_updated_at
        ) {
          return (
            <EligibleWorkers
              timezone={data?.venue?.timezone}
              count={data?.offering_stats?.workers_available}
              lastUpdate={data?.offering_stats?.workers_available_updated_at}
            />
          )
        }
        return '—'
      },
      parameters: (data: Data) => ({ timeZone: data?.venue?.timezone }),
    },
  ],
  [
    'offering_stats.next_offer_at',
    {
      header: <div style={{ marginLeft: 10 }}>Next offers will be sent at</div>,
      parameters: (data: Data) => ({ timeZone: data?.venue?.timezone }),
      type: structureTypes.TYPE_TIMESTAMP,
      defaultValue: '—',
    },
  ],
  [
    'offering_stats.fill_automation_active',
    {
      header: (
        <>
          Fill automation status{' '}
          <Tooltip label="This section shows if the fill automation for the worker pool has kicked in and the remaining worker supply eligible for offers.">
            <InformationIcon size="sm" sx={{ verticalAlign: 'top' }} color="grey" />
          </Tooltip>
        </>
      ),
      calc: (data: Data) => {
        return data?.offering_stats?.fill_automation_active ? 'Active' : 'Not active'
      },
    },
  ],
  [
    'offering_stats.fill_automation_workers_available',
    {
      header: <div style={{ marginLeft: 10 }}>Job candidate pool size</div>,
      calc: (data: Data) => {
        if (
          (data?.offering_stats?.fill_automation_workers_available ||
            data?.offering_stats?.fill_automation_workers_available === 0) &&
          data?.offering_stats?.fill_automation_workers_available_updated_at
        ) {
          return (
            <EligibleWorkers
              timezone={data?.venue?.timezone}
              count={data?.offering_stats?.fill_automation_workers_available}
              lastUpdate={data?.offering_stats?.fill_automation_workers_available_updated_at}
            />
          )
        }
        return '—'
      },
      parameters: (data: Data) => ({ timeZone: data?.venue?.timezone }),
    },
  ],
]

import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { flowRight } from 'lodash-es'

import Acp from 'syft-acp-uikit'
import { setFilter } from 'syft-acp-core/store/filters/actions'
import { assignConductReviews, fetchConductReviews } from 'syft-acp-core/store/conductReviews/actions'
import { ConductReviewEntity } from 'syft-acp-core/store/types'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import { showModal } from 'syft-acp-core/store/modals/actions'
import DataBanReasons from 'syft-acp-core/components/EditableTable/DataBanReasons/DataBanReasons'
import { FilterText } from 'syft-acp-util/components/FilterForm'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import FilterOutcomeReached from 'syft-acp-util/components/FilterForm/FilterOutcomeReached'
import FilterReasons from 'syft-acp-util/components/FilterForm/FilterReasons'
import FilterAssignees from 'syft-acp-util/components/FilterForm/FilterAssignees'
import FilterUSStatesMultiselect from 'syft-acp-util/components/FilterForm/FilterUSStatesMultiselect'
import useResetFilterAssignees from 'syft-acp-util/hooks/useResetFilterAssignees'
import useFetchEntity from 'syft-acp-util/hooks/useFetchEntity'
import useBasicTracking from 'syft-acp-util/hooks/useBasicTracking'

import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import {
  BanReviewsListProps as Props,
  BanReviewsListEntityProps as EntityProps,
} from './BanReviewsList.types'
import { trackingEvents } from './BanReviewsList.tracking'

export const entityConnector = createEntityListConnector<ConductReviewEntity>({
  entityActions: {
    fetchEntities: ({ options }: Record<string, any>) => fetchConductReviews({ payload: { options } }),
  },
  entityStore: 'conductReviews',
})

export const storeConnector = connect(
  state => ({
    tsLastAssigned: state.conductReviews.tsLastAssigned,
    me: state.auth.userData,
  }),
  (dispatch, { actions }: EntityProps) => ({
    actions: {
      ...actions,
      ...bindActionCreators({ assignConductReviews, showModal, setFilter }, dispatch),
    },
  }),
)

const getRowLink = ({ id }: ConductReviewEntity) =>
  `/conduct/ban-reviews/view/${id}?exclude_never_activated=true&paginated=true`

const selectReasons = (rowData: ConductReviewEntity) => <DataBanReasons data={rowData} />

const BanReviewsList = ({ actions, data, isLoadingData, query, tsLastAssigned, me }: Props) => {
  const reasonQueryKey = 'ban_reason_id'
  const statesFilterFeatureEnabled = useFlexFlagIsOn('pte_16778_conduct_reviews_state_filter')

  const { onClickAssign, onChangeOutcome, onChangeReason, onChangeAssignee } = useBasicTracking(
    trackingEvents,
    reasonQueryKey,
  )

  const onChangeReasonFn = (val: string) => {
    onChangeReason(val)
    actions.setFilter(reasonQueryKey, val)
  }

  const onClickAssignFn = () => {
    onClickAssign()
    actions.assignConductReviews()
  }

  useResetFilterAssignees(actions.setFilter, tsLastAssigned, me.id + '')

  useFetchEntity(query, actions.fetchEntities)

  return (
    <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.US]}>
      {(isUS: boolean) => (
        <Acp.EntityList data={data} isLoading={isLoadingData} idKeyValue="id" hasPagination>
          <Acp.Actions>
            <Acp.Button disabled={isLoadingData} kind="primary" onClick={onClickAssignFn}>
              Assign new cases to me
            </Acp.Button>
            <Acp.FilterGroup title="Outcome reached">
              <FilterOutcomeReached onChange={onChangeOutcome} />
            </Acp.FilterGroup>
            <Acp.FilterGroup title="Reason">
              <FilterReasons type="bans" onChange={onChangeReasonFn} allowAny />
            </Acp.FilterGroup>
            <Acp.FilterGroup title="Assignee">
              <FilterAssignees storeKey="conductReviews" onChange={onChangeAssignee} />
            </Acp.FilterGroup>
            <Acp.FilterGroup title="Worker Email">
              <FilterText type="email" name="email" placeholder="Type at least 5 characters" minLength={5} />
            </Acp.FilterGroup>
            {isUS && statesFilterFeatureEnabled && (
              <Acp.FilterGroup title="US States">
                <FilterUSStatesMultiselect name="federated_states" />
              </Acp.FilterGroup>
            )}
          </Acp.Actions>
          <Acp.Table rowLink={getRowLink}>
            {isUS && <Acp.Col.Text value="federated_state_code" header="State" align="left" isMinimal />}
            <Acp.Col.Text
              value="worker.id"
              header="Worker ID"
              align="left"
              colLink={(rowData: ConductReviewEntity) => `/entity/workers/view/${rowData.worker.id}`}
              isMinimal
              isNumeric
            />
            <Acp.Col.Text value="worker.full_name" header="Name" align="left" isMinimal />
            <Acp.Col.Text value="worker.email" header="Email" align="left" isMinimal />
            <Acp.Col.Text value={selectReasons} header="Why ban candidate" align="left" isMinimal />
            <Acp.Col.Text
              value="worker.active_strikes_count"
              header="Total active strikes"
              align="left"
              colLink={(rowData: ConductReviewEntity) =>
                `/entity/workers/view/${rowData.worker.id}/strikes/list`
              }
              isMinimal
              isNumeric
            />
            <Acp.Col.Text
              value="worker.completed_shifts_count"
              header="Completed shifts"
              align="left"
              isMinimal
              isNumeric
            />
            <Acp.Col.DateTime
              value="worker.last_strike_activated_at"
              header="Last strike"
              align="left"
              isMinimal
            />
            <Acp.Col.Text value="assignee.full_name" header="Case assigned to" align="left" isMinimal />
          </Acp.Table>
        </Acp.EntityList>
      )}
    </CountrySpecific>
  )
}

export default flowRight(entityConnector, storeConnector)(BanReviewsList)

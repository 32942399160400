// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { get, partialRight } from 'lodash-es'

import { checkDateQueryMatch, getDateRange } from 'syft-acp-core/lib/dateFilter'

// Functions for calling getDateRange() and checkDateQueryMatch() with the shift start/end vars
// prefilled for making shifts/listings API calls, and with timezone always included.

export const getDateRangeShift = partialRight(getDateRange, 'start_time_gte', 'start_time_lte', true, true)

export const getDateRangeShiftCurrentTime = partialRight(
  getDateRange,
  'start_time_gte',
  'start_time_lte',
  true,
  true,
  true,
)

export const getDateRangeCsat = partialRight(getDateRange, 'from_date', 'to_date', false)

export const checkDateQueryMatchShift = partialRight(
  checkDateQueryMatch,
  'start_time_gte',
  'start_time_lte',
  true,
)

/**
 * Filters the allocation name of an agency shift.
 *
 * The allocation name contains multiple values separated by commas,
 * and this might be replaced later by separate values.
 */
export const filterAllocationName = (subRowData: { allocation_name: string } & Record<string, any>) =>
  get(subRowData, 'allocation_name', '').split(',').join(', ')

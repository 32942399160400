import React from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'

import RecordSection from 'syft-acp-core/components/RecordSection'
import { Store } from 'syft-acp-core/store'

import { Props, AllProps } from './types'
import { structure } from './structure'

const ListingProgressiveOffer = ({ listingShift, venue }: AllProps) => {
  const data = { ...listingShift, venue }
  return (
    <Row className="group-row">
      <Col md={12} sm={12}>
        <RecordSection data={data} structure={structure} title="Offer status" />
      </Col>
    </Row>
  )
}

export default connect((state: Store, props: Props) => ({
  listingShift: state?.listingShifts?.entityDetail?.[props?.shiftId] || {},
}))(ListingProgressiveOffer)

import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Acp from 'syft-acp-uikit'
import classNames from 'classnames'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import * as matchingExplainabilityActions from 'syft-acp-core/store/matching-explainability/actions'
import * as employerVenuesActions from 'syft-acp-core/actions/employer-venues-filtered'
import * as employerTokenActions from 'syft-acp-core/actions/employer-tokens'
import * as employerActions from 'syft-acp-core/store/employers/actions'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import RecordSection from 'syft-acp-core/components/RecordSection'
import { Button } from 'syft-acp-atoms/Button'
import FilterAutocompleteWorkers from 'syft-acp-util/components/FilterForm/FilterAutocompleteWorkers'

import { getWorkersFilterResults, getTitleWithTooltip, getFiltersData, getReasonValue } from './helpers'
import { trackingEvents } from './MatchingExplainability.tracking'
import { MatchingExplainabilityListProps, MatchingExplainabilityProps } from './types'
import S from './MatchingExplainability.module.scss'

export const storeConnector = connect(
  (state, props: MatchingExplainabilityProps) => ({
    matchingExplainability: state?.matchingExplainability?.entityMap,
    roles: state.roles?.entityMap,
    venues: state.employerVenuesFiltered?.entityMap,
    skills: state.skills?.entityMap,
    cities: state.cities?.entityMap,
    userData: state?.auth?.userData,
    token: state.employerToken?.employers?.[props?.employerID],
    isLoadingData: state?.matchingExplainability?.isLoadingData,
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(
        {
          ...matchingExplainabilityActions,
          ...employerVenuesActions,
          ...employerTokenActions,
          ...employerActions,
        },
        dispatch,
      ),
    },
  }),
)

export const MatchingExplainabilityList = ({
  matchingExplainability,
  actions,
  roles,
  skills,
  employerName,
  venues,
  cities,
  employerID,
  jobId,
  userData,
  token,
  isLoadingData,
  venueID,
}: MatchingExplainabilityListProps) => {
  const conflictingShiftIdsEnabled = useFlexFlagIsOn(
    'pte_36055_include_availability_filters_conflicting_shifts',
  )
  const triggerEvent = useTrackingTrigger()

  const [workerId, setWorkerId] = useState<number | null>(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    actions.fetchEmployerFilteredVenuesList(employerID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGoButton = useCallback(() => {
    actions.fetchMatchingExplainability({ workerId, jobId })
    setLoaded(true)
    triggerEvent(trackingEvents.SAVE_BUTTON.CLICKED, { workerId, jobId })
  }, [actions, jobId, triggerEvent, workerId])

  const filteredFilters = {
    id: 1,
    metadata: workerId ? matchingExplainability?.[workerId]?.metadata : {},
    filters: workerId ? getFiltersData(matchingExplainability?.[workerId]) : [],
  }
  const handleChangeWorkerId = useCallback((id: number) => {
    setLoaded(false)
    setWorkerId(id)
  }, [])
  return (
    <RecordSection title="Flexer filter check">
      <div className={S['matching-explainability__container']}>
        <div className={S['matching-explainability__text-box']}>
          <FilterAutocompleteWorkers placeholder="Name/ID" disabled={false} callback={handleChangeWorkerId} />
          <Button
            className={S['matching-explainability__search-button']}
            variant="secondary"
            onClick={handleGoButton}
          >
            Go
          </Button>
        </div>
        {workerId && matchingExplainability?.[workerId] && !isLoadingData && loaded && (
          <div
            className={classNames([!filteredFilters?.filters?.length && S['matching-explainability__table']])}
          >
            <Acp.EntityList
              data={{ data: [filteredFilters] }}
              isLoading={false}
              idKeyValue="id"
              hasPagination
            >
              <Acp.Table hasBottomSection callbackAndLink>
                <Acp.Col.Text
                  value={getWorkersFilterResults}
                  header="Filter results"
                  align="center"
                  isMinimal
                />
                {filteredFilters?.filters?.length && (
                  <Acp.SubRows value="filters">
                    <Acp.Col.Text
                      width="300px"
                      value={getTitleWithTooltip}
                      header="Filter not passed"
                      isMinimal
                    />
                    <Acp.Col.Text
                      value={getReasonValue({
                        workerId,
                        roles,
                        skills,
                        employerName,
                        employerID,
                        venues,
                        cities,
                        actions,
                        userData,
                        token,
                        venueID,
                        conflictingShiftIdsEnabled,
                      })}
                      header="Reason not passed"
                    />
                  </Acp.SubRows>
                )}
              </Acp.Table>
            </Acp.EntityList>
          </div>
        )}
      </div>
    </RecordSection>
  )
}

export default storeConnector(MatchingExplainabilityList)

// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import SubNavigation, { SubLink } from 'syft-acp-atoms/SubNavigation'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'

const SubNavigationFinance = () => (
  <SubNavigation>
    <SubLink to="/finance/invoicing">Invoicing</SubLink>
    <SubLink to="/finance/auto-offer-rate">Auto offer rate</SubLink>
    <SubLink to="/finance/payments">Payments</SubLink>
    <SubLink to="/finance/overpayments">Overpayments</SubLink>
    <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.GB]}>
      <SubLink to="/finance/holiday-pay">Holiday pay</SubLink>
      <SubLink to="/finance/leave-requests">Leavers Report</SubLink>
    </CountrySpecific>
    <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.US]}>
      <SubLink to="/finance/insurance-class-codes">Insurance class codes</SubLink>
    </CountrySpecific>
    <SubLink to="/finance/rate-cards/agency">Agency rate cards</SubLink>
    <SubLink to="/finance/bank-holidays">Bank Holidays</SubLink>
    <SubLink to="/finance/payroll">Payroll</SubLink>
  </SubNavigation>
)

export default SubNavigationFinance

import * as React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

import PageView from 'syft-acp-core/components/PageView'
import BanReviewList from 'syft-acp-core/entities2/BanReviewList'

import ConductSubNav from './ConductSubNav'

export const BanReviewsList = () => (
  <>
    <ConductSubNav />
    <PageView title={['Conduct reviews', 'Viewing conduct reviews']}>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <BanReviewList />
          </Col>
        </Row>
      </Grid>
    </PageView>
  </>
)

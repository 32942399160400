// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

// Necessary while migrating to ducks in order to avoid code duplication
export * from '../store/action-types'

export const ADMIN_CLIENT_APPLICATIONS_FETCH_BEGIN = 'syftacp/ADMIN_CLIENT_APPLICATIONS_FETCH_BEGIN'
export const ADMIN_CLIENT_APPLICATIONS_FETCH_FAILED = 'syftacp/ADMIN_CLIENT_APPLICATIONS_FETCH_FAILED'
export const ADMIN_CLIENT_APPLICATIONS_FETCH_SUCCEEDED = 'syftacp/ADMIN_CLIENT_APPLICATIONS_FETCH_SUCCEEDED'
export const ADMIN_CONVERSATION_FETCH_BEGIN = 'syftacp/ADMIN_CONVERSATION_FETCH_BEGIN'
export const ADMIN_CONVERSATION_FETCH_FAILED = 'syftacp/ADMIN_CONVERSATION_FETCH_FAILED'
export const ADMIN_CONVERSATION_FETCH_SUCCEEDED = 'syftacp/ADMIN_CONVERSATION_FETCH_SUCCEEDED'
export const ADMIN_CONVERSATION_MESSAGES_FETCH_BEGIN = 'syftacp/ADMIN_CONVERSATION_MESSAGES_FETCH_BEGIN'
export const ADMIN_CONVERSATION_MESSAGES_FETCH_FAILED = 'syftacp/ADMIN_CONVERSATION_MESSAGES_FETCH_FAILED'
export const ADMIN_CONVERSATION_MESSAGES_FETCH_SUCCEEDED =
  'syftacp/ADMIN_CONVERSATION_MESSAGES_FETCH_SUCCEEDED'
export const ADMIN_CONVERSATIONS_FETCH_BEGIN = 'syftacp/ADMIN_CONVERSATIONS_FETCH_BEGIN'
export const ADMIN_CONVERSATIONS_FETCH_FAILED = 'syftacp/ADMIN_CONVERSATIONS_FETCH_FAILED'
export const ADMIN_CONVERSATIONS_FETCH_SUCCEEDED = 'syftacp/ADMIN_CONVERSATIONS_FETCH_SUCCEEDED'
export const ADMIN_MANAGER_CREATE_BEGIN = 'syftacp/ADMIN_MANAGER_CREATE_BEGIN'
export const ADMIN_MANAGER_CREATE_FAILED = 'syftacp/ADMIN_MANAGER_CREATE_FAILED'
export const ADMIN_MANAGER_CREATE_SUCCEEDED = 'syftacp/ADMIN_MANAGER_CREATE_SUCCEEDED'
export const ADMIN_MANAGER_DELETE_BEGIN = 'syftacp/ADMIN_MANAGER_DELETE_BEGIN'
export const ADMIN_MANAGER_DELETE_FAILED = 'syftacp/ADMIN_MANAGER_DELETE_FAILED'
export const ADMIN_MANAGER_DELETE_SUCCEEDED = 'syftacp/ADMIN_MANAGER_DELETE_SUCCEEDED'
export const ADMIN_MANAGER_FETCH_BEGIN = 'syftacp/ADMIN_MANAGER_FETCH_BEGIN'
export const ADMIN_MANAGER_FETCH_FAILED = 'syftacp/ADMIN_MANAGER_FETCH_FAILED'
export const ADMIN_MANAGER_FETCH_SUCCEEDED = 'syftacp/ADMIN_MANAGER_FETCH_SUCCEEDED'
export const ADMIN_MANAGER_UPDATE_BEGIN = 'syftacp/ADMIN_MANAGER_UPDATE_BEGIN'
export const ADMIN_MANAGER_UPDATE_FAILED = 'syftacp/ADMIN_MANAGER_UPDATE_FAILED'
export const ADMIN_MANAGER_UPDATE_SUCCEEDED = 'syftacp/ADMIN_MANAGER_UPDATE_SUCCEEDED'
export const ADMIN_MANAGERS_FETCH_BEGIN = 'syftacp/ADMIN_MANAGERS_FETCH_BEGIN'
export const ADMIN_MANAGERS_FETCH_FAILED = 'syftacp/ADMIN_MANAGERS_FETCH_FAILED'
export const ADMIN_MANAGERS_FETCH_SUCCEEDED = 'syftacp/ADMIN_MANAGERS_FETCH_SUCCEEDED'
export const ADMIN_TIMESHEETS_BULK_EDIT_BEGIN = 'syftacp/ADMIN_TIMESHEETS_BULK_EDIT_BEGIN'
export const ADMIN_TIMESHEETS_BULK_EDIT_FAILED = 'syftacp/ADMIN_TIMESHEETS_BULK_EDIT_FAILED'
export const ADMIN_TIMESHEETS_BULK_EDIT_SUCCEEDED = 'syftacp/ADMIN_TIMESHEETS_BULK_EDIT_SUCCEEDED'
export const ADMIN_TIMESHEETS_EDIT_ALL_BEGIN = 'syftacp/ADMIN_TIMESHEETS_EDIT_ALL_BEGIN'
export const ADMIN_TIMESHEETS_EDIT_ALL_FAILED = 'syftacp/ADMIN_TIMESHEETS_EDIT_ALL_FAILED'
export const ADMIN_TIMESHEETS_EDIT_ALL_SUCCEEDED = 'syftacp/ADMIN_TIMESHEETS_EDIT_ALL_SUCCEEDED'
export const AGENCIES_CLEAR_ITEMS = 'syftacp/AGENCIES_CLEAR_ITEMS'
export const AGENCIES_FETCH_BEGIN = 'syftacp/AGENCIES_FETCH_BEGIN'
export const AGENCIES_FETCH_FAILED = 'syftacp/AGENCIES_FETCH_FAILED'
export const AGENCIES_FETCH_SUCCEEDED = 'syftacp/AGENCIES_FETCH_SUCCEEDED'
export const AGENCIES_LIST_FETCH_BEGIN = 'syftacp/AGENCIES_LIST_FETCH_BEGIN'
export const AGENCIES_LIST_FETCH_FAILED = 'syftacp/AGENCIES_LIST_FETCH_FAILED'
export const AGENCIES_LIST_FETCH_SUCCEEDED = 'syftacp/AGENCIES_LIST_FETCH_SUCCEEDED'
export const AGENCIES_LIST_CREATE_BEGIN = 'syftacp/AGENCIES_LIST_CREATE_BEGIN'
export const AGENCIES_LIST_CREATE_FAILED = 'syftacp/AGENCIES_LIST_CREATE_FAILED'
export const AGENCIES_LIST_CREATE_SUCCEEDED = 'syftacp/AGENCIES_LIST_CREATE_SUCCEEDED'
export const AGENCY_ACCOUNTS_FETCH_BEGIN = 'syftacp/AGENCY_ACCOUNTS_FETCH_BEGIN'
export const AGENCY_ACCOUNTS_FETCH_FAILED = 'syftacp/AGENCY_ACCOUNTS_FETCH_FAILED'
export const AGENCY_ACCOUNTS_FETCH_SUCCEEDED = 'syftacp/AGENCY_ACCOUNTS_FETCH_SUCCEEDED'
export const AGENCY_ACCOUNTS_PERMISSIONS_FETCH_BEGIN = 'syftacp/AGENCY_ACCOUNTS_PERMISSIONS_FETCH_BEGIN'
export const AGENCY_ACCOUNTS_PERMISSIONS_FETCH_FAILED = 'syftacp/AGENCY_ACCOUNTS_PERMISSIONS_FETCH_FAILED'
export const AGENCY_ACCOUNTS_PERMISSIONS_FETCH_SUCCEEDED =
  'syftacp/AGENCY_ACCOUNTS_PERMISSIONS_FETCH_SUCCEEDED'
export const AGENCY_ACCOUNTS_UPDATE_BEGIN = 'syftacp/AGENCY_ACCOUNTS_UPDATE_BEGIN'
export const AGENCY_ACCOUNTS_UPDATE_FAILED = 'syftacp/AGENCY_ACCOUNTS_UPDATE_FAILED'
export const AGENCY_ACCOUNTS_UPDATE_SUCCEEDED = 'syftacp/AGENCY_ACCOUNTS_UPDATE_SUCCEEDED'
export const AGENCY_BOOKING_FETCH_BEGIN = 'syftacp/AGENCY_BOOKING_FETCH_BEGIN'
export const AGENCY_BOOKING_FETCH_FAILED = 'syftacp/AGENCY_BOOKING_FETCH_FAILED'
export const AGENCY_BOOKING_FETCH_SUCCEEDED = 'syftacp/AGENCY_BOOKING_FETCH_SUCCEEDED'
export const AGENCY_BOOKING_SAVE_BEGIN = 'syftacp/AGENCY_BOOKING_SAVE_BEGIN'
export const AGENCY_BOOKING_SAVE_FAILED = 'syftacp/AGENCY_BOOKING_SAVE_FAILED'
export const AGENCY_BOOKING_SAVE_SUCCEEDED = 'syftacp/AGENCY_BOOKING_SAVE_SUCCEEDED'
export const AGENCY_BOOKING_UPDATE = 'syftacp/AGENCY_BOOKING_UPDATE'
export const AGENCY_PLATFORM_DELETE_BEGIN = 'syftacp/AGENCY_PLATFORM_DELETE_BEGIN'
export const AGENCY_PLATFORM_DELETE_FAILED = 'syftacp/AGENCY_PLATFORM_DELETE_FAILED'
export const AGENCY_PLATFORM_DELETE_SUCCEEDED = 'syftacp/AGENCY_PLATFORM_DELETE_SUCCEEDED'
export const AGENCY_PROFILE_FETCH_BEGIN = 'syftacp/AGENCY_PROFILE_FETCH_BEGIN'
export const AGENCY_PROFILE_FETCH_FAILED = 'syftacp/AGENCY_PROFILE_FETCH_FAILED'
export const AGENCY_PROFILE_FETCH_SUCCEEDED = 'syftacp/AGENCY_PROFILE_FETCH_SUCCEEDED'
export const AGENCY_PROFILE_UPDATE_BEGIN = 'syftacp/AGENCY_PROFILE_UPDATE_BEGIN'
export const AGENCY_PROFILE_UPDATE_FAILED = 'syftacp/AGENCY_PROFILE_UPDATE_FAILED'
export const AGENCY_PROFILE_UPDATE_SUCCEEDED = 'syftacp/AGENCY_PROFILE_UPDATE_SUCCEEDED'
export const AGENCY_RATECARD_SAVE_BEGIN = 'syftacp/AGENCY_RATECARD_SAVE_BEGIN'
export const AGENCY_RATECARD_SAVE_FAILED = 'syftacp/AGENCY_RATECARD_SAVE_FAILED'
export const AGENCY_RATECARD_SAVE_SUCCEEDED = 'syftacp/AGENCY_RATECARD_SAVE_SUCCEEDED'
export const AGENCY_RATECARD_UPDATE_BEGIN = 'syftacp/AGENCY_RATECARD_UPDATE_BEGIN'
export const AGENCY_RATECARD_UPDATE_FAILED = 'syftacp/AGENCY_RATECARD_UPDATE_FAILED'
export const AGENCY_RATECARD_UPDATE_SUCCEEDED = 'syftacp/AGENCY_RATECARD_UPDATE_SUCCEEDED'
export const AGENCY_RATECARD_FETCH_BEGIN = 'syftacp/AGENCY_RATECARD_FETCH_BEGIN'
export const AGENCY_RATECARD_FETCH_FAILED = 'syftacp/AGENCY_RATECARD_FETCH_FAILED'
export const AGENCY_RATECARD_FETCH_SUCCEEDED = 'syftacp/AGENCY_RATECARD_FETCH_SUCCEEDED'
export const AGENCY_RATECARDS_FETCH_BEGIN = 'syftacp/AGENCY_RATECARDS_FETCH_BEGIN'
export const AGENCY_RATECARDS_FETCH_FAILED = 'syftacp/AGENCY_RATECARDS_FETCH_FAILED'
export const AGENCY_RATECARDS_FETCH_SUCCEEDED = 'syftacp/AGENCY_RATECARDS_FETCH_SUCCEEDED'
export const APP_REMOVE_UNUSED_DATA = 'syftacp/APP_REMOVE_UNUSED_DATA'
export const APP_REMOVE_UNUSED_DATA_AFTER = 'syftacp/APP_REMOVE_UNUSED_DATA_AFTER'
export const APP_RESET_AND_RELOAD = 'syftacp/APP_RESET_AND_RELOAD'
export const APP_SAVE_CURRENT_VERSION = 'syftacp/APP_SAVE_CURRENT_VERSION'
export const APP_SET_COUNTRY_CODE = 'syftacp/APP_SET_COUNTRY_CODE'
export const APP_UPDATE_LOCAL_STORAGE_SIZE = 'syftacp/APP_UPDATE_LOCAL_STORAGE_SIZE'
export const ATTACHMENT_DELETE_BEGIN = 'syftacp/ATTACHMENT_DELETE_BEGIN'
export const ATTACHMENT_DELETE_FAILED = 'syftacp/ATTACHMENT_DELETE_FAILED'
export const ATTACHMENT_DELETE_SUCCEEDED = 'syftacp/ATTACHMENT_DELETE_SUCCEEDED'
export const ATTACHMENT_FETCH_BEGIN = 'syftacp/ATTACHMENT_FETCH_BEGIN'
export const ATTACHMENT_FETCH_FAILED = 'syftacp/ATTACHMENT_FETCH_FAILED'
export const ATTACHMENT_FETCH_SUCCEEDED = 'syftacp/ATTACHMENT_FETCH_SUCCEEDED'
export const ATTACHMENT_SAVE_BEGIN = 'syftacp/ATTACHMENT_SAVE_BEGIN'
export const ATTACHMENT_SAVE_FAILED = 'syftacp/ATTACHMENT_SAVE_FAILED'
export const ATTACHMENT_SAVE_SUCCEEDED = 'syftacp/ATTACHMENT_SAVE_SUCCEEDED'
export const ATTACHMENTS_FETCH_BEGIN = 'syftacp/ATTACHMENTS_FETCH_BEGIN'
export const ATTACHMENTS_FETCH_FAILED = 'syftacp/ATTACHMENTS_FETCH_FAILED'
export const ATTACHMENTS_FETCH_SUCCEEDED = 'syftacp/ATTACHMENTS_FETCH_SUCCEEDED'
export const AUTH_DELETE = 'syftacp/AUTH_DELETE'
export const AUTH_LOG_OUT = 'syftacp/AUTH_LOG_OUT'
export const AUTH_LOG_OUT_BEGIN = 'syftacp/AUTH_LOG_OUT_BEGIN'
export const AUTH_REFRESH_OLD_TOKEN_BEGIN = 'syftacp/AUTH_REFRESH_OLD_TOKEN_BEGIN'
export const AUTH_REFRESH_OLD_TOKEN_FAILED = 'syftacp/AUTH_REFRESH_OLD_TOKEN_FAILED'
export const AUTH_REFRESH_OLD_TOKEN_SUCCEEDED = 'syftacp/AUTH_REFRESH_OLD_TOKEN_SUCCEEDED'
export const AUTH_REFRESH_TOKEN_BEGIN = 'syftacp/AUTH_REFRESH_TOKEN_BEGIN'
export const AUTH_REFRESH_TOKEN_FAILED = 'syftacp/AUTH_REFRESH_TOKEN_FAILED'
export const AUTH_REFRESH_TOKEN_FORCE_UPDATE = 'syftacp/AUTH_REFRESH_TOKEN_FORCE_UPDATE'
export const AUTH_REFRESH_TOKEN_SUCCEEDED = 'syftacp/AUTH_REFRESH_TOKEN_SUCCEEDED'
export const BANK_HOLIDAY_REGIONS_FETCH_BEGIN = 'syftacp/BANK_HOLIDAY_REGIONS_FETCH_BEGIN'
export const BANK_HOLIDAY_REGIONS_FETCH_FAILED = 'syftacp/BANK_HOLIDAY_REGIONS_FETCH_FAILED'
export const BANK_HOLIDAY_REGIONS_FETCH_SUCCEEDED = 'syftacp/BANK_HOLIDAY_REGIONS_FETCH_SUCCEEDED'
export const CITIES_FETCH_BEGIN = 'syftacp/CITIES_FETCH_BEGIN'
export const CITIES_FETCH_FAILED = 'syftacp/CITIES_FETCH_FAILED'
export const CITIES_FETCH_SUCCEEDED = 'syftacp/CITIES_FETCH_SUCCEEDED'
export const COMMISSION_CREATE_BEGIN = 'syftacp/COMMISSION_CREATE_BEGIN'
export const COMMISSION_CREATE_FAILED = 'syftacp/COMMISSION_CREATE_FAILED'
export const COMMISSION_CREATE_SUCCEEDED = 'syftacp/COMMISSION_CREATE_SUCCEEDED'
export const COMMISSIONS_FETCH_BEGIN = 'syftacp/COMMISSIONS_FETCH_BEGIN'
export const COMMISSIONS_FETCH_FAILED = 'syftacp/COMMISSIONS_FETCH_FAILED'
export const COMMISSIONS_FETCH_SUCCEEDED = 'syftacp/COMMISSIONS_FETCH_SUCCEEDED'
export const COUNTRIES_FETCH_BEGIN = 'syftacp/COUNTRIES_FETCH_BEGIN'
export const COUNTRIES_FETCH_FAILED = 'syftacp/COUNTRIES_FETCH_FAILED'
export const COUNTRIES_FETCH_SUCCEEDED = 'syftacp/COUNTRIES_FETCH_SUCCEEDED'
export const CSAT_FETCH_CSV_BEGIN = 'syftacp/CSAT_FETCH_CSV_BEGIN'
export const CSAT_FETCH_CSV_FAILED = 'syftacp/CSAT_FETCH_CSV_FAILED'
export const CSAT_FETCH_CSV_SUCCEEDED = 'syftacp/CSAT_FETCH_CSV_SUCCEEDED'
export const CSAT_RESULTS_FETCH_BEGIN = 'syftacp/CSAT_RESULTS_FETCH_BEGIN'
export const CSAT_RESULTS_FETCH_FAILED = 'syftacp/CSAT_RESULTS_FETCH_FAILED'
export const CSAT_RESULTS_FETCH_SUCCEEDED = 'syftacp/CSAT_RESULTS_FETCH_SUCCEEDED'
export const CSAT_SCORE_FETCH_BEGIN = 'syftacp/CSAT_SCORE_FETCH_BEGIN'
export const CSAT_SCORE_FETCH_FAILED = 'syftacp/CSAT_SCORE_FETCH_FAILED'
export const CSAT_SCORE_FETCH_SUCCEEDED = 'syftacp/CSAT_SCORE_FETCH_SUCCEEDED'
export const CSAT_SKIPPED_FETCH_BEGIN = 'syftacp/CSAT_SKIPPED_FETCH_BEGIN'
export const CSAT_SKIPPED_FETCH_FAILED = 'syftacp/CSAT_SKIPPED_FETCH_FAILED'
export const CSAT_SKIPPED_FETCH_SUCCEEDED = 'syftacp/CSAT_SKIPPED_FETCH_SUCCEEDED'
export const DELETE_PREV_EXP_BEGIN = 'syftacp/DELETE_PREV_EXP_BEGIN'
export const DELETE_PREV_EXP_FAILED = 'syftacp/DELETE_PREV_EXP_FAILED'
export const DELETE_PREV_EXP_SUCCEEDED = 'syftacp/DELETE_PREV_EXP_SUCCEEDED'
export const DELETE_WORKER_FROM_NETWORK_BEGIN = 'syftacp/DELETE_WORKER_FROM_NETWORK_BEGIN'
export const DELETE_WORKER_FROM_NETWORK_FAILED = 'syftacp/DELETE_WORKER_FROM_NETWORK_FAILED'
export const DELETE_WORKER_FROM_NETWORK_SUCCEEDED = 'syftacp/DELETE_WORKER_FROM_NETWORK_SUCCEEDED'
export const DELETED_RTW_DOCUMENTS_FETCH_BEGIN = 'syftacp/DELETED_RTW_DOCUMENTS_FETCH_BEGIN'
export const DELETED_RTW_DOCUMENTS_FETCH_FAILED = 'syftacp/DELETED_RTW_DOCUMENTS_FETCH_FAILED'
export const DELETED_RTW_DOCUMENTS_FETCH_SUCCEEDED = 'syftacp/DELETED_RTW_DOCUMENTS_FETCH_SUCCEEDED'
export const DESKTOP_NOTIFICATION_SHOW = 'syftacp/DESKTOP_NOTIFICATION_SHOW'
export const DESKTOP_NOTIFICATIONS_REQ_PERMISSION = 'syftacp/DESKTOP_NOTIFICATIONS_REQ_PERMISSION'
export const DESKTOP_NOTIFICATIONS_SET = 'syftacp/DESKTOP_NOTIFICATIONS_SET'
export const DESKTOP_NOTIFICATIONS_TOGGLE = 'syftacp/DESKTOP_NOTIFICATIONS_TOGGLE'
export const EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_BEGIN = 'syftacp/EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_BEGIN'
export const EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_FAILED =
  'syftacp/EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_FAILED'
export const EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_SUCCEEDED =
  'syftacp/EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_SUCCEEDED'
export const EMPLOYER_ACCOUNT_DISABLE_BEGIN = 'syftacp/EMPLOYER_ACCOUNT_DISABLE_BEGIN'
export const EMPLOYER_ACCOUNT_DISABLE_FAILED = 'syftacp/EMPLOYER_ACCOUNT_DISABLE_FAILED'
export const EMPLOYER_ACCOUNT_DISABLE_SUCCEEDED = 'syftacp/EMPLOYER_ACCOUNT_DISABLE_SUCCEEDED'
export const EMPLOYER_ACCOUNT_FETCH_BEGIN = 'syftacp/EMPLOYER_ACCOUNT_FETCH_BEGIN'
export const EMPLOYER_ACCOUNT_FETCH_FAILED = 'syftacp/EMPLOYER_ACCOUNT_FETCH_FAILED'
export const EMPLOYER_ACCOUNT_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_ACCOUNT_FETCH_SUCCEEDED'
export const EMPLOYER_ACCOUNT_INVITE_BEGIN = 'syftacp/EMPLOYER_ACCOUNT_INVITE_BEGIN'
export const EMPLOYER_ACCOUNT_INVITE_FAILED = 'syftacp/EMPLOYER_ACCOUNT_INVITE_FAILED'
export const EMPLOYER_ACCOUNT_INVITE_SUCCEEDED = 'syftacp/EMPLOYER_ACCOUNT_INVITE_SUCCEEDED'
export const EMPLOYER_ACCOUNT_SAVE_BEGIN = 'syftacp/EMPLOYER_ACCOUNT_SAVE_BEGIN'
export const EMPLOYER_ACCOUNT_SAVE_FAILED = 'syftacp/EMPLOYER_ACCOUNT_SAVE_FAILED'
export const EMPLOYER_ACCOUNT_SAVE_SUCCEEDED = 'syftacp/EMPLOYER_ACCOUNT_SAVE_SUCCEEDED'
export const EMPLOYER_ACCOUNTS_FETCH_BEGIN = 'syftacp/EMPLOYER_ACCOUNTS_FETCH_BEGIN'
export const EMPLOYER_ACCOUNTS_FETCH_FAILED = 'syftacp/EMPLOYER_ACCOUNTS_FETCH_FAILED'
export const EMPLOYER_ACCOUNTS_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_ACCOUNTS_FETCH_SUCCEEDED'

export const EMPLOYER_ACCOUNT_LOGIN_BEGIN = 'syftacp/EMPLOYER_ACCOUNT_LOGIN_BEGIN'
export const EMPLOYER_ACCOUNT_LOGIN_FAILED = 'syftacp/EMPLOYER_ACCOUNT_LOGIN_FAILED'
export const EMPLOYER_ACCOUNT_LOGIN_SUCCEEDED = 'syftacp/EMPLOYER_ACCOUNT_LOGIN_SUCCEEDED'

export const EMPLOYER_BLOCKLIST_ADD_BEGIN = 'syftacp/EMPLOYER_BLOCKLIST_ADD_BEGIN'
export const EMPLOYER_BLOCKLIST_ADD_FAILED = 'syftacp/EMPLOYER_BLOCKLIST_ADD_FAILED'
export const EMPLOYER_BLOCKLIST_ADD_SUCCEEDED = 'syftacp/EMPLOYER_BLOCKLIST_ADD_SUCCEEDED'
export const EMPLOYER_BLOCKLIST_FETCH_BEGIN = 'syftacp/EMPLOYER_BLOCKLIST_FETCH_BEGIN'
export const EMPLOYER_BLOCKLIST_FETCH_FAILED = 'syftacp/EMPLOYER_BLOCKLIST_FETCH_FAILED'
export const EMPLOYER_BLOCKLIST_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_BLOCKLIST_FETCH_SUCCEEDED'
export const EMPLOYER_BLOCKLIST_REMOVE_BEGIN = 'syftacp/EMPLOYER_BLOCKLIST_REMOVE_BEGIN'
export const EMPLOYER_BLOCKLIST_REMOVE_FAILED = 'syftacp/EMPLOYER_BLOCKLIST_REMOVE_FAILED'
export const EMPLOYER_BLOCKLIST_REMOVE_SUCCEEDED = 'syftacp/EMPLOYER_BLOCKLIST_REMOVE_SUCCEEDED'
export const EMPLOYER_EVENTS_FETCH_BEGIN = 'syftacp/EMPLOYER_EVENTS_FETCH_BEGIN'
export const EMPLOYER_EVENTS_FETCH_FAILED = 'syftacp/EMPLOYER_EVENTS_FETCH_FAILED'
export const EMPLOYER_EVENTS_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_EVENTS_FETCH_SUCCEEDED'

export const EMPLOYER_NETWORK_FETCH_BEGIN = 'syftacp/EMPLOYER_NETWORK_FETCH_BEGIN'
export const EMPLOYER_NETWORK_FETCH_FAILED = 'syftacp/EMPLOYER_NETWORK_FETCH_FAILED'
export const EMPLOYER_NETWORK_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_NETWORK_FETCH_SUCCEEDED'
export const EMPLOYER_NETWORKS_FETCH_BEGIN = 'syftacp/EMPLOYER_NETWORKS_FETCH_BEGIN'
export const EMPLOYER_NETWORKS_FETCH_FAILED = 'syftacp/EMPLOYER_NETWORKS_FETCH_FAILED'
export const EMPLOYER_NETWORKS_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_NETWORKS_FETCH_SUCCEEDED'
export const EMPLOYER_RATECARD_SAVE_BEGIN = 'syftacp/EMPLOYER_RATECARD_SAVE_BEGIN'
export const EMPLOYER_RATECARD_SAVE_FAILED = 'syftacp/EMPLOYER_RATECARD_SAVE_FAILED'
export const EMPLOYER_RATECARD_SAVE_SUCCEEDED = 'syftacp/EMPLOYER_RATECARD_SAVE_SUCCEEDED'
export const EMPLOYER_RATECARD_UPDATE_BEGIN = 'syftacp/EMPLOYER_RATECARD_UPDATE_BEGIN'
export const EMPLOYER_RATECARD_UPDATE_FAILED = 'syftacp/EMPLOYER_RATECARD_UPDATE_FAILED'
export const EMPLOYER_RATECARD_UPDATE_SUCCEEDED = 'syftacp/EMPLOYER_RATECARD_UPDATE_SUCCEEDED'
export const EMPLOYER_RATECARDS_FETCH_BEGIN = 'syftacp/EMPLOYER_RATECARDS_FETCH_BEGIN'
export const EMPLOYER_RATECARDS_FETCH_FAILED = 'syftacp/EMPLOYER_RATECARDS_FETCH_FAILED'
export const EMPLOYER_RATECARDS_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_RATECARDS_FETCH_SUCCEEDED'
export const EMPLOYER_RATINGS_FETCH_BEGIN = 'syftacp/EMPLOYER_RATINGS_FETCH_BEGIN'
export const EMPLOYER_RATINGS_FETCH_FAILED = 'syftacp/EMPLOYER_RATINGS_FETCH_FAILED'
export const EMPLOYER_RATINGS_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_RATINGS_FETCH_SUCCEEDED'
export const EMPLOYER_SALES_FORCE_FETCH_BEGIN = 'syftacp/EMPLOYER_SALES_FORCE_FETCH_BEGIN'
export const EMPLOYER_SALES_FORCE_FETCH_FAILED = 'syftacp/EMPLOYER_SALES_FORCE_FETCH_FAILED'
export const EMPLOYER_SALES_FORCE_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_SALES_FORCE_FETCH_SUCCEEDED'
export const EMPLOYER_SALES_FORCE_SAVE_BEGIN = 'syftacp/EMPLOYER_SALES_FORCE_SAVE_BEGIN'
export const EMPLOYER_SALES_FORCE_SAVE_FAILED = 'syftacp/EMPLOYER_SALES_FORCE_SAVE_FAILED'
export const EMPLOYER_SALES_FORCE_SAVE_SUCCEEDED = 'syftacp/EMPLOYER_SALES_FORCE_SAVE_SUCCEEDED'

export const EMPLOYER_TOKEN_FETCH_BEGIN = 'syftacp/EMPLOYER_TOKEN_FETCH_BEGIN'
export const EMPLOYER_TOKEN_FETCH_FAILED = 'syftacp/EMPLOYER_TOKEN_FETCH_FAILED'
export const EMPLOYER_TOKEN_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_TOKEN_FETCH_SUCCEEDED'
export const EMPLOYER_TOKEN_SAVE = 'syftacp/EMPLOYER_TOKEN_SAVE'
export const EMPLOYER_TEMPLATES_FETCH_BEGIN = 'syftacp/EMPLOYER_TEMPLATES_FETCH_BEGIN'
export const EMPLOYER_TEMPLATES_FETCH_FAILED = 'syftacp/EMPLOYER_TEMPLATES_FETCH_FAILED'
export const EMPLOYER_TEMPLATES_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_TEMPLATES_FETCH_SUCCEEDED'

export const ERROR_ADD = 'syftacp/ERROR_ADD'
export const EXPIRING_DOCUMENTS_FETCH_BEGIN = 'syftacp/EXPIRING_DOCUMENTS_FETCH_BEGIN'
export const EXPIRING_DOCUMENTS_FETCH_FAILED = 'syftacp/EXPIRING_DOCUMENTS_FETCH_FAILED'
export const EXPIRING_DOCUMENTS_FETCH_SUCCEEDED = 'syftacp/EXPIRING_DOCUMENTS_FETCH_SUCCEEDED'
export const EXPIRING_DOCUMENTS_RESOLVE_BEGIN = 'syftacp/EXPIRING_DOCUMENTS_RESOLVE_BEGIN'
export const EXPIRING_DOCUMENTS_RESOLVE_FAILED = 'syftacp/EXPIRING_DOCUMENTS_RESOLVE_FAILED'
export const EXPIRING_DOCUMENTS_RESOLVE_SUCCEEDED = 'syftacp/EXPIRING_DOCUMENTS_RESOLVE_SUCCEEDED'
export const HEALTH_FETCH_BEGIN = 'syftacp/HEALTH_FETCH_BEGIN'
export const HEALTH_FETCH_FAILED = 'syftacp/HEALTH_FETCH_FAILED'
export const HEALTH_FETCH_SUCCEEDED = 'syftacp/HEALTH_FETCH_SUCCEEDED'
export const INDUSTRIES_FETCH_BEGIN = 'syftacp/INDUSTRIES_FETCH_BEGIN'
export const INDUSTRIES_FETCH_FAILED = 'syftacp/INDUSTRIES_FETCH_FAILED'
export const INDUSTRIES_FETCH_SUCCEEDED = 'syftacp/INDUSTRIES_FETCH_SUCCEEDED'
export const IMPORT_EVENT_LOGS_FETCH_BEGIN = 'syftacp/IMPORT_EVENT_LOGS_FETCH_BEGIN'
export const IMPORT_EVENT_LOGS_FETCH_FAILED = 'syftacp/IMPORT_EVENT_LOGS_FETCH_FAILED'
export const IMPORT_EVENT_LOGS_FETCH_SUCCEEDED = 'syftacp/IMPORT_EVENT_LOGS_FETCH_SUCCEEDED'
export const LOGIN_BEGIN = 'syftacp/LOGIN_BEGIN'
export const LOGIN_FAILED = 'syftacp/LOGIN_FAILED'
export const LOGIN_SUCCEEDED = 'syftacp/LOGIN_SUCCEEDED'
export const LOGIN_SSO_BEGIN = 'syftacp/LOGIN_SSO_BEGIN'
export const LOGIN_SSO_FAILED = 'syftacp/LOGIN_SSO_FAILED'
export const LOGIN_SSO_SUCCEEDED = 'syftacp/LOGIN_SSO_SUCCEEDED'
export const LOGIN_REDEEM_BEGIN = 'syftacp/LOGIN_REDEEM_BEGIN'
export const LOGIN_REDEEM_FAILED = 'syftacp/LOGIN_REDEEM_FAILED'
export const LOGIN_REDEEM_SUCCEEDED = 'syftacp/LOGIN_REDEEM_SUCCEEDED'
export const MARKETING_REF_CLAIMS_FETCH_BEGIN = 'syftacp/MARKETING_REF_CLAIMS_FETCH_BEGIN'
export const MARKETING_REF_CLAIMS_FETCH_FAILED = 'syftacp/MARKETING_REF_CLAIMS_FETCH_FAILED'
export const MARKETING_REF_CLAIMS_FETCH_SUCCEEDED = 'syftacp/MARKETING_REF_CLAIMS_FETCH_SUCCEEDED'
export const MARKETING_REF_CODES_FETCH_BEGIN = 'syftacp/MARKETING_REF_CODES_FETCH_BEGIN'
export const MARKETING_REF_CODES_FETCH_FAILED = 'syftacp/MARKETING_REF_CODES_FETCH_FAILED'
export const MARKETING_REF_CODES_FETCH_SUCCEEDED = 'syftacp/MARKETING_REF_CODES_FETCH_SUCCEEDED'
export const NO_SHOW_REASONS_FETCH_BEGIN = 'syftacp/NO_SHOW_REASONS_FETCH_BEGIN'
export const NO_SHOW_REASONS_FETCH_FAILED = 'syftacp/NO_SHOW_REASONS_FETCH_FAILED'
export const NO_SHOW_REASONS_FETCH_SUCCEEDED = 'syftacp/NO_SHOW_REASONS_FETCH_SUCCEEDED'
export const NOTIFICATIONS_GLOBALLY_ENABLE_IF_UNSET = 'syftacp/NOTIFICATIONS_GLOBALLY_ENABLE_IF_UNSET'
export const NOTIFICATIONS_GLOBALLY_TOGGLE = 'syftacp/NOTIFICATIONS_GLOBALLY_TOGGLE'
export const OVERPAYMENT_SAVE_BEGIN = 'syftacp/OVERPAYMENT_SAVE_BEGIN'
export const OVERPAYMENT_SAVE_FAILED = 'syftacp/OVERPAYMENT_SAVE_FAILED'
export const OVERPAYMENT_SAVE_SUCCEEDED = 'syftacp/OVERPAYMENT_SAVE_SUCCEEDED'
export const OVERPAYMENTS_FETCH_BEGIN = 'syftacp/OVERPAYMENTS_FETCH_BEGIN'
export const OVERPAYMENTS_FETCH_FAILED = 'syftacp/OVERPAYMENTS_FETCH_FAILED'
export const OVERPAYMENTS_FETCH_SUCCEEDED = 'syftacp/OVERPAYMENTS_FETCH_SUCCEEDED'
export const PAYMENTS_FETCH_BEGIN = 'syftacp/PAYMENTS_FETCH_BEGIN'
export const PAYMENTS_FETCH_FAILED = 'syftacp/PAYMENTS_FETCH_FAILED'
export const PAYMENTS_FETCH_SUCCEEDED = 'syftacp/PAYMENTS_FETCH_SUCCEEDED'
export const PREV_EXP_FETCH_BEGIN = 'syftacp/PREV_EXP_FETCH_BEGIN'
export const PREV_EXP_FETCH_FAILED = 'syftacp/PREV_EXP_FETCH_FAILED'
export const PREV_EXP_FETCH_SUCCEEDED = 'syftacp/PREV_EXP_FETCH_SUCCEEDED'
export const PREV_EXP_LIST_BEGIN = 'syftacp/PREV_EXP_LIST_BEGIN'
export const PREV_EXP_LIST_FAILED = 'syftacp/PREV_EXP_LIST_FAILED'
export const PREV_EXP_LIST_SUCCEEDED = 'syftacp/PREV_EXP_LIST_SUCCEEDED'
export const PREV_EXP_SAVE_BEGIN = 'syftacp/PREV_EXP_SAVE_BEGIN'
export const PREV_EXP_SAVE_FAILED = 'syftacp/PREV_EXP_SAVE_FAILED'
export const PREV_EXP_SAVE_SUCCEEDED = 'syftacp/PREV_EXP_SAVE_SUCCEEDED'
export const REHYDRATION_COMPLETE = 'syftacp/REHYDRATION_COMPLETE'
export const RESET_PASSWORD_BEGIN = 'syftacp/RESET_PASSWORD_BEGIN'
export const RESET_PASSWORD_FAILED = 'syftacp/RESET_PASSWORD_FAILED'
export const RESET_PASSWORD_SUCCEEDED = 'syftacp/RESET_PASSWORD_SUCCEEDED'
export const REVIEW_APPS_FETCH_BEGIN = 'syftacp/REVIEW_APPS_FETCH_BEGIN'
export const REVIEW_APPS_FETCH_FAILED = 'syftacp/REVIEW_APPS_FETCH_FAILED'
export const REVIEW_APPS_FETCH_SUCCEEDED = 'syftacp/REVIEW_APPS_FETCH_SUCCEEDED'
export const REVIEW_APPS_SET = 'syftacp/REVIEW_APPS_SET'
export const SHIFT_DELETE_BEGIN = 'syftacp/SHIFT_DELETE_BEGIN'
export const SHIFT_DELETE_FAILED = 'syftacp/SHIFT_DELETE_FAILED'
export const SHIFT_DELETE_SUCCEEDED = 'syftacp/SHIFT_DELETE_SUCCEEDED'
export const SHIFT_STOP_NEW_WORKERS_BEGIN = 'syftacp/SHIFT_STOP_NEW_WORKERS_BEGIN'
export const SHIFT_STOP_NEW_WORKERS_FAILED = 'syftacp/SHIFT_STOP_NEW_WORKERS_FAILED'
export const SHIFT_STOP_NEW_WORKERS_SUCCEEDED = 'syftacp/SHIFT_STOP_NEW_WORKERS_SUCCEEDED'
export const STATS_EMPLOYER_FETCH_BEGIN = 'syftacp/STATS_EMPLOYER_FETCH_BEGIN'
export const STATS_EMPLOYER_FETCH_FAILED = 'syftacp/STATS_EMPLOYER_FETCH_FAILED'
export const STATS_EMPLOYER_FETCH_SUCCEEDED = 'syftacp/STATS_EMPLOYER_FETCH_SUCCEEDED'
export const STATS_GENERAL_FETCH_BEGIN = 'syftacp/STATS_GENERAL_FETCH_BEGIN'
export const STATS_GENERAL_FETCH_FAILED = 'syftacp/STATS_GENERAL_FETCH_FAILED'
export const STATS_GENERAL_FETCH_SUCCEEDED = 'syftacp/STATS_GENERAL_FETCH_SUCCEEDED'
export const STATS_WORKER_FETCH_BEGIN = 'syftacp/STATS_WORKER_FETCH_BEGIN'
export const STATS_WORKER_FETCH_FAILED = 'syftacp/STATS_WORKER_FETCH_FAILED'
export const STATS_WORKER_FETCH_SUCCEEDED = 'syftacp/STATS_WORKER_FETCH_SUCCEEDED'
export const STOP_COMMISSION_BEGIN = 'syftacp/STOP_COMMISSION_BEGIN'
export const STOP_COMMISSION_FAILED = 'syftacp/STOP_COMMISSION_FAILED'
export const STOP_COMMISSION_SUCCEEDED = 'syftacp/STOP_COMMISSION_SUCCEEDED'
export const STRIKES_FETCH_BEGIN = 'syftacp/STRIKES_FETCH_BEGIN'
export const STRIKES_FETCH_FAILED = 'syftacp/STRIKES_FETCH_FAILED'
export const STRIKES_FETCH_SUCCEEDED = 'syftacp/STRIKES_FETCH_SUCCEEDED'
export const TIME_DEPENDENT_RATES_SAVE_BEGIN = 'syftacp/TIME_DEPENDENT_RATES_SAVE_BEGIN'
export const TIME_DEPENDENT_RATES_SAVE_FAILED = 'syftacp/TIME_DEPENDENT_RATES_SAVE_FAILED'
export const TIME_DEPENDENT_RATES_SAVE_SUCCEEDED = 'syftacp/TIME_DEPENDENT_RATES_SAVE_SUCCEEDED'
export const TIME_DEPENDENT_RATES_UPDATE_BEGIN = 'syftacp/TIME_DEPENDENT_RATES_UPDATE_BEGIN'
export const TIME_DEPENDENT_RATES_UPDATE_FAILED = 'syftacp/TIME_DEPENDENT_RATES_UPDATE_FAILED'
export const TIME_DEPENDENT_RATES_UPDATE_SUCCEEDED = 'syftacp/TIME_DEPENDENT_RATES_UPDATE_SUCCEEDED'
export const AGENCY_TIME_DEPENDENT_RATES_SAVE_BEGIN = 'syftacp/AGENCY_TIME_DEPENDENT_RATES_SAVE_BEGIN'
export const AGENCY_TIME_DEPENDENT_RATES_SAVE_FAILED = 'syftacp/AGENCY_TIME_DEPENDENT_RATES_SAVE_FAILED'
export const AGENCY_TIME_DEPENDENT_RATES_SAVE_SUCCEEDED = 'syftacp/AGENCY_TIME_DEPENDENT_RATES_SAVE_SUCCEEDED'
export const AGENCY_TIME_DEPENDENT_RATES_UPDATE_BEGIN = 'syftacp/AGENCY_TIME_DEPENDENT_RATES_UPDATE_BEGIN'
export const AGENCY_TIME_DEPENDENT_RATES_UPDATE_FAILED = 'syftacp/AGENCY_TIME_DEPENDENT_RATES_UPDATE_FAILED'
export const AGENCY_TIME_DEPENDENT_RATES_UPDATE_SUCCEEDED =
  'syftacp/AGENCY_TIME_DEPENDENT_RATES_UPDATE_SUCCEEDED'
export const TIMEKEEPING_BULK_APPROVE_BEGIN = 'syftacp/TIMEKEEPING_BULK_APPROVE_BEGIN'
export const TIMEKEEPING_BULK_APPROVE_FAILED = 'syftacp/TIMEKEEPING_BULK_APPROVE_FAILED'
export const TIMEKEEPING_BULK_APPROVE_SUCCEEDED = 'syftacp/TIMEKEEPING_BULK_APPROVE_SUCCEEDED'
export const UPLOAD_IMAGE_BEGIN = 'syftacp/UPLOAD_IMAGE_BEGIN'
export const UPLOAD_IMAGE_FAILED = 'syftacp/UPLOAD_IMAGE_FAILED'
export const UPLOAD_IMAGE_SUCCEEDED = 'syftacp/UPLOAD_IMAGE_SUCCEEDED'
export const USER_BAN_BEGIN = 'syftacp/USER_BAN_BEGIN'
export const USER_BAN_FAILED = 'syftacp/USER_BAN_FAILED'
export const USER_BAN_SUCCEEDED = 'syftacp/USER_BAN_SUCCEEDED'
export const USER_DELETE_BEGIN = 'syftacp/USER_DELETE_BEGIN'
export const USER_DELETE_FAILED = 'syftacp/USER_DELETE_FAILED'
export const USER_DELETE_SUCCEEDED = 'syftacp/USER_DELETE_SUCCEEDED'
export const USER_FETCH_BEGIN = 'syftacp/USER_FETCH_BEGIN'
export const USER_FETCH_FAILED = 'syftacp/USER_FETCH_FAILED'
export const USER_FETCH_SUCCEEDED = 'syftacp/USER_FETCH_SUCCEEDED'
export const USER_PATCH_BEGIN = 'syftacp/USER_PATCH_BEGIN'
export const USER_PATCH_FAILED = 'syftacp/USER_PATCH_FAILED'
export const USER_PATCH_SUCCEEDED = 'syftacp/USER_PATCH_SUCCEEDED'
export const USER_SETTINGS_SET_DEFAULTS = 'syftacp/USER_SETTINGS_SET_DEFAULTS'
export const USER_SETTINGS_SET_TIMEZONE = 'syftacp/USER_SETTINGS_SET_TIMEZONE'
export const USER_SETTINGS_SET_VALUE = 'syftacp/USER_SETTINGS_SET_VALUE'
export const USER_SETTINGS_SET_VALUES = 'syftacp/USER_SETTINGS_SET_VALUES'
export const USERS_FETCH_BEGIN = 'syftacp/USERS_FETCH_BEGIN'
export const USERS_FETCH_FAILED = 'syftacp/USERS_FETCH_FAILED'
export const USERS_FETCH_SUCCEEDED = 'syftacp/USERS_FETCH_SUCCEEDED'
export const UPLOAD_TRANSFERRED_WORKER_CSV_BEGIN = 'UPLOAD_TRANSFERRED_WORKER_CSV_BEGIN'
export const UPLOAD_TRANSFERRED_WORKER_CSV_SUCCEEDED = 'UPLOAD_TRANSFERRED_WORKER_CSV_SUCCEEDED'
export const UPLOAD_TRANSFERRED_WORKER_CSV_FAILED = 'UPLOAD_TRANSFERRED_WORKER_CSV_FAILED'
export const WORKER_TRANSFER_RESEND_INVITATION_BEGIN = 'syftacp/WORKER_TRANSFER_RESEND_INVITATION_BEGIN'
export const WORKER_TRANSFER_RESEND_INVITATION_SUCCEEDED =
  'syftacp/WORKER_TRANSFER_RESEND_INVITATION_SUCCEEDED'
export const WORKER_TRANSFER_RESEND_INVITATION_FAILED = 'syftacp/WORKER_TRANSFER_RESEND_INVITATION_FAILED'

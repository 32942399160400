import * as types from './action-types'

import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

export default entityReducer('agency-platform', {
  [entityTypes.ENTITY_CREATE_BEGIN]: types.CREATE_AGENCY_PLATFORM_POST_BEGIN,
  [entityTypes.ENTITY_CREATE_SUCCEEDED]: types.CREATE_AGENCY_PLATFORM_POST_SUCCEEDED,
  [entityTypes.ENTITY_CREATE_FAILED]: types.CREATE_AGENCY_PLATFORM_POST_FAILED,
})

/* eslint-disable */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, isEmpty } from 'lodash-es'
import { fetchIndustries } from 'syft-acp-core/actions/industries'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'

import FilterHierarchyMultiselect from './FilterHierarchyMultiselect'

const FilterIndustryRolesMultiselect = ({
  employerId,
  industries,
  defaultValues = [],
  actions,
  oneIndustry,
  onChange,
}) => {
  useEffect(() => {
    if (!industries.length) {
      actions.fetchIndustries(defaultValues.length ? null : employerId)
    }
  }, [employerId, actions])

  const industriesOptions = !!employerId
    ? industries.map(industry => {
        return {
          value: industry.id,
          label: industry.title,
          options: industry?.roles?.map(role => ({ value: role.id, label: role.title })),
        }
      })
    : []
  const values = industries.length
    ? defaultValues.map(industry => {
        const findIndustry = industriesOptions.find(item => industry.value === item.value)
        return {
          value: industry.value,
          label: get(findIndustry, 'label'),
          options: get(industry, 'options', []).map(option =>
            get(findIndustry, 'options', []).find(item => {
              return option.value === item.value
            }),
          ),
        }
      })
    : []
  return (
    <>
      <FilterHierarchyMultiselect
        values={isEmpty(get(values, '[0].options', [])) ? [] : values}
        onChange={onChange}
        options={industriesOptions}
      />
      {oneIndustry && isEmpty(get(values, '[0].options', [])) && (
        <div style={{ color: 'red' }}>The field industries + roles can't be empty</div>
      )}
    </>
  )
}

export default connect(
  (state, { employerId, oneIndustry }) => {
    return {
      industries: oneIndustry
        ? entityList$(state.industries, { employer_id: null })
        : entityList$(state.industries, { employer_id: employerId }),
      venues: state.employerVenuesFiltered,
    }
  },
  dispatch => ({
    actions: bindActionCreators({ fetchIndustries }, dispatch),
  }),
)(FilterIndustryRolesMultiselect)

import React from 'react'
import SubNavigation, { SubLink } from 'syft-acp-atoms/SubNavigation'

const ConductSubNav = () => {
  return (
    <SubNavigation>
      <SubLink to="/conduct/appeals">Appeals</SubLink>
      <SubLink to="/conduct/ban-reviews">Ban reviews</SubLink>
    </SubNavigation>
  )
}

export default ConductSubNav

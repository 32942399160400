import React from 'react'

import FilterReasons from 'syft-acp-util/components/FilterForm/FilterReasons'

import { DataConductReviewOutcomeDropdownProps as Props } from './DataConductReviewOutcomeDropdown.types'

const DataConductReviewOutcomeDropdown = ({ disabled = false, editable = false, data, onChange }: Props) => {
  if (editable)
    return (
      <FilterReasons
        allowAny
        type="bans"
        headerLabel="Keep open"
        onChange={onChange}
        value={data.outcome as any}
        disabled={disabled}
        banPrefix
      />
    )
  return <div className="plain-text">{data.ban ? data.ban?.reason?.display_name : 'No ban'}</div>
}

export default DataConductReviewOutcomeDropdown

/* eslint-disable prefer-promise-reject-errors */
// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { store } from 'syft-acp-core/store'
import { apiRequest, handleCall } from './call'

/**
 * Retrieves a list of users. Implements pagination.
 *
 * @param params {Object}
 * @param params.options {Object} Other query options
 */
export const listUsers = ({ options = {} }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/users', reqArgs: options })
    handleCall(req, resolve, reject, true, options)
  })

/**
 * Retrieves a user data from server.
 *
 * @param params {Object}
 * @param params.id {Number} The user ID
 */
export const fetchUser = ({ id }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/users/${id}` })
    handleCall(req, resolve, reject, true)
  })

/**
 * Patches a user timezone settings.
 *
 * @param params {Object}
 * @param params.id {Number} The user ID
 */
export const patchUser = ({ options: { id, timezone } }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/users/${id}`,
      reqArgs: { timezone },
      method: 'PATCH',
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Request a new auth token.
 */
export const refreshAuth = () =>
  new Promise((resolve, reject) => {
    const { refresh_token } = store.getState().auth.oauthData
    const req = apiRequest({ path: `/users/refresh`, reqArgs: { refresh_token }, method: 'POST' })
    handleCall(req, resolve, reject, true)
  })

/**
 * Retrieves an log events data from server.
 *
 */
export const fetchImportLogEvents = ({ query }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/imports/external_jobs`, reqArgs: query })
    handleCall(req, resolve, reject, true)
  })

/**
 * Runs autocomplete search for verified employers.
 *
 * @param {String} query String to query the database with
 * @returns {Promise} List of employers on promise fulfillment
 */
export const employerAutocomplete = async query =>
  await fetch(apiRequest({ path: '/admin/employers/autocomplete', reqArgs: { query, verified: true } }))
    .then(resp => resp.json())
    .then(json => json.suggestions)

/**
 * Retrieves a list of employer template names.
 *
 * @param params {Object}
 * @param params.employerID {Object} the employer ID
 */
export const listEmployerTemplates = ({ employerID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/employers/${employerID}/listing_templates` })
    handleCall(req, resolve, reject, true, { employerID })
  })

/**
 * Retrieves employer's network.
 *
 * @param params {Object}
 * @param params.employerID {Number} the employer ID
 */
export const listEmployerNetworks = ({ options = {}, toCSV = false }) =>
  new Promise((resolve, reject) => {
    const { employerID, ...reqArgs } = options
    const req = apiRequest({
      path: `/admin/employers/${employerID}/trusted_network_workers`,
      reqArgs,
      returnAll: toCSV,
    })
    if (toCSV) {
      handleCall(
        req,
        resolve,
        reject,
        true,
        options,
        null,
        toCSV,
        'employer-networks',
        false,
        [],
        `/admin/employers/${employerID}/trusted_network_workers`,
      )
    } else {
      handleCall(req, resolve, reject, true, options, null, toCSV, 'employer-networks')
    }
  })

/**
 * Retrieves a worker from employer's network.
 *
 * @param params {Object}
 * @param params.employerID {Number} the employer ID
 * @param params.workerID {Number} the worker ID
 */
export const fetchEmployerNetwork = ({ employerID, workerID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/employers/${employerID}/trusted_network_workers/${workerID}` })
    handleCall(req, resolve, reject, true)
  })

/**
 * Removes a worker from employer's network.
 *
 * @param params {Object}
 * @param params.employerID {Number} the employer ID
 * @param params.id {Number} the worker ID
 */
export const deleteWorkerFromNetwork = ({ employerID, workerID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/employers/${employerID}/trusted_network_workers/${workerID}`,
      reqArgs: null,
      method: 'DELETE',
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Retrieves a list of listings. Implements pagination.
 */
export const listListings = ({ options = {} }) =>
  new Promise((resolve, reject) => {
    const reqArgs = {
      ...options,
      // Per CMS2-871. Also in 'syft-acp-core/api/resources/admin/shifts.js'.
      // See also 'syft-acp-core/entities/ShiftList/index.ts' and 'syft-acp-core/entities/ListingList/index.ts'.
      platform: 'syft',
    }
    const req = apiRequest({ path: '/admin/listings', reqArgs })
    // Pass the original options back so we don't save the cache under a different key.
    handleCall(req, resolve, reject, true, options)
  })

/**
 * Resets the user's password.
 *
 * @param {String} email User email
 */
export const resetPassword = ({ email }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/users/recover_password', reqArgs: { email }, method: 'POST' })
    handleCall(req, resolve, reject)
  })

/**
 * Attempts to log in a user. Yields either the user's data or an error.
 * If no username or password are entered, the promise resolves instantly.
 * The API combines this with OAuth login, meaning we also get
 * the appropriate tokens for doing authenticated requests.
 *
 * @param email User email
 * @param password Password
 */
export const loginUser = (email, password, onlyAdmins = true) =>
  new Promise((resolve, reject) => {
    // Reject if we don't have a username and password.
    if (email === '' || password === '') {
      reject({
        message: 'Enter your email and password.',
      })
    }

    // Request the user to be logged in.
    const req = apiRequest({ path: '/users/login', reqArgs: { email, password }, method: 'POST' })
    handleCall(
      req,
      // If we only allow admins to sign in, check whether this user is one.
      ({ payload }) =>
        !onlyAdmins || payload.user.admin
          ? resolve(payload)
          : reject({
              message: 'Only admin accounts may log in.',
            }),
      reject,
    )
  })

/**
 * Attempts to log in a user. Yields either the user's data or an error.
 * If no email is entered, the promise returns the OneLogin url.
 * The API combines this with OAuth login, meaning we also get
 * the appropriate tokens for doing authenticated requests.
 *
 * @param email User email
 */
export const loginUserWithSSO = email =>
  new Promise((resolve, reject) => {
    // Reject if we don't have a SSO email.
    if (email === '') {
      reject({
        message: 'Enter your SSO email.',
      })
    }
    // Request the user to get the login type url.
    const req = apiRequest({ path: '/saml_auth/login_type', reqArgs: { email }, method: 'POST' })
    handleCall(req, resolve, reject)
  })

/**
 * Attempts to log in a user via Code from SSO
 *
 * @param code User code
 */
export const loginUserRedeemSSO = code =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/saml_auth/redeem', reqArgs: { code }, method: 'GET' })
    handleCall(req, resolve, reject)
  })

export const logoutUser = reqArgs =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/oauth/revoke', reqArgs, method: 'POST' })
    handleCall(req, resolve, reject)
  })

/**
 * Uploads an image file.
 *
 * @param params {Object} Function parameters as object
 * @param params.uuid {String} any pre-generated UUID string
 * @param params.file {Object} file object taken from input or drag'n'drop event.
 */
export const uploadImage = ({ uuid, file }) =>
  new Promise((resolve, reject) => {
    const reqArgs = new FormData()
    reqArgs.append('file', file)
    const req = apiRequest({ path: `/images/${uuid}`, reqArgs, method: 'PUT', utilizePagination: false })
    handleCall(req, resolve, reject)
  })

/**
 * Lists a worker previous experience
 *
 * @param params {Object}
 * @param params.workerID {Number} ID of the worker
 */
export const listPrevExp = ({ workerID, options = {} }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/${workerID}/non_syft_experiences` })
    handleCall(req, resolve, reject, false, options)
  })

/**
 * Get a worker previous experience record
 *
 * @param params {Object}
 * @param params.workerID {Number} ID of the worker
 * @param params.prevExpID {Number} ID of the previous experience record
 */
export const fetchPrevExp = ({ workerID, prevExpID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/${workerID}/non_syft_experiences/${prevExpID}` })
    handleCall(req, resolve, reject)
  })

/**
 * Removes a worker previous experience record
 *
 * @param params {Object}
 * @param params.prevExpID {Number} ID of the previous experience record
 */
export const deletePrevExp = ({ prevExpID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/workers/profile/non_syft_experiences/${prevExpID}`,
      reqArgs: null,
      method: 'DELETE',
    })
    handleCall(req, resolve, reject)
  })

/**
 * Saves a worker previous experience record
 *
 * @param params {Object}
 * @param params.prevExpID {Number} ID of the previous experience record
 * @param params.data {Object} The previous experience data
 */
export const savePrevExp = ({ prevExpID, workerID, data }) =>
  new Promise((resolve, reject) => {
    const req = !prevExpID
      ? apiRequest({ path: `/admin/workers/${workerID}/non_syft_experiences`, reqArgs: data, method: 'POST' })
      : apiRequest({
          path: `/admin/workers/${workerID}/non_syft_experiences/${prevExpID}`,
          reqArgs: data,
          method: 'PUT',
        })
    handleCall(req, resolve, reject)
  })

export const deleteShift = ({ shiftID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/shifts/${shiftID}`, reqArgs: null, method: 'DELETE' })
    handleCall(req, resolve, reject)
  })

export const verifyPaycom = ({ id, status }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/workers/onboarding/us/paycom/${id}`,
      reqArgs: { status },
      method: 'PATCH',
    })
    handleCall(req, resolve, reject)
  })

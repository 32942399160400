import React from 'react'
import SubNavigation, { SubLink } from 'syft-acp-atoms/SubNavigation'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

const WorkersSubNavigation = () => {
  const showInterviewsTab = useFlexFlagIsOn('worker_interviews_list_tab_visible')
  return (
    <SubNavigation>
      <SubLink to="/entity/workers/list">All workers</SubLink>
      <SubLink to="/entity/workers/transferred/list">Transferred workers</SubLink>
      {showInterviewsTab ? <SubLink to="/o/a/entity/workers/interviews">Interviews</SubLink> : null}
    </SubNavigation>
  )
}

export default WorkersSubNavigation

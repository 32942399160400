// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'syft-acp-atoms/Button'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import entityList from 'syft-acp-util/entityList'
import * as employerAccountsActions from 'syft-acp-core/actions/employer-accounts'
import * as tableType from 'syft-acp-core/components/EntityList/'
import { FilterGroup, FilterText } from 'syft-acp-util/components/FilterForm'

import EmployerAccountsListWrapper from './EmployerAccountsListWrapper'
import './EmployerAccountsList.css'

// Returns a JSX node containing the level.
const userLevel = data => {
  const label = data.level[0].toUpperCase() + data.level.slice(1)
  return (
    <span className="type-label">
      {label}
      {data.owner ? ' (owner)' : ''}
    </span>
  )
}

// TODO: this needs to be uncommented after Chris approvement ticket ADMN-490
const filterFormat = (
  <div>
    {/* <FilterGroup title="Venue">
      <FilterText name="venue" placeholder="Venue" />
    </FilterGroup> */}
    <FilterGroup title="Name">
      <FilterText name="name" placeholder="Name" />
    </FilterGroup>
    <FilterGroup title="E-mail">
      <FilterText name="email" placeholder="E-mail" />
    </FilterGroup>
  </div>
)
const actionFormat = []

class EmployerAccountsList extends PureComponent {
  static propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    employerID: PropTypes.number.isRequired,
    employerAccounts: PropTypes.array.isRequired,
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.impersonationRedirectUrl !== prevProps.impersonationRedirectUrl &&
      this.props.impersonationRedirectUrl
    ) {
      const { impersonationRedirectUrl, userId } = this.props
      window.open(`${impersonationRedirectUrl}&impersonatorId=${userId}`)
    }
  }

  handleLoginAs = data => {
    return (
      <Button
        external
        target="_blank"
        onClick={() => {
          const { actions } = this.props
          actions.employerAccountLogin(data.id)
        }}
      >
        Login as user
      </Button>
    )
  }

  tableFormat = [
    { type: tableType.SELECTOR },
    { type: tableType.TXT, val: 'id', header: 'ID', numeric: true },
    { type: tableType.TXT, val: 'user.id', header: 'UID', expl: 'User ID', numeric: true },
    { type: tableType.IMAGE, header: 'Pic', val: 'picture' },
    { type: tableType.TXT, val: 'name', classes: ['nowrap'], header: 'Name' },
    { type: tableType.TXT, val: 'user.email', header: 'Email', classes: ['main'], numeric: true },
    { type: tableType.PHONE_NUMBER, val: 'telephone_number', header: 'Tel. nr', classes: ['nowrap'] },
    { type: tableType.TXT, val: userLevel, header: 'Level', classes: ['nowrap', 'level'] },
    {
      type: tableType.CUSTOM_COMPONENT,
      val: this.handleLoginAs,
      classes: ['minimal'],
      header: 'Actions',
      hasLink: false,
    },
    { type: tableType.DATETIME, val: 'created_at', header: 'Created at', classes: ['nowrap'] },
    {
      type: tableType.DATETIME,
      val: 'invite_accepted_at',
      header: 'Invite accepted at',
      classes: ['nowrap'],
    },
  ]

  render() {
    const EntityList = entityList(
      EmployerAccountsListWrapper,
      this.tableFormat,
      filterFormat,
      actionFormat,
      'employerAccounts',
      {
        fetchEntities: employerAccountsActions.fetchEmployerAccounts,
      },
      {
        idKey: 'id',
        fullWidth: true,
        rowClasses: data => data && `account-type-${data.level} ${data.owner ? 'is-owner' : ''}`,
      },
    )

    return (
      <div className="EmployerAccountsList">
        <EntityList
          {...this.props}
          employerID={this.props.employerID}
          query={{ employerID: this.props.employerID }}
        />
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(employerAccountsActions, dispatch) }
}

function mapStateToProps(state) {
  return {
    userId: state?.auth?.userData?.id,
    impersonationRedirectUrl: state?.employerAccounts?.impersonationRedirectUrl,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployerAccountsList)

import { ConductReviewEntity } from 'syft-acp-core/store/types'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import * as structureTypes from 'syft-acp-core/components/EditableTable'
import { CountryCode } from './BanReviewDetail.types'

export const getDataStructure = (isClosed = false, country: CountryCode) => [
  {
    title: 'Ban review information',
    structure: [
      [
        'created_at',
        {
          header: 'Created',
          type: structureTypes.TYPE_DATE,
        },
      ],
      [
        'assigned_at',
        {
          header: 'Assigned',
          type: structureTypes.TYPE_DATE,
        },
      ],
      [
        'assignee.id',
        {
          header: 'Assigned to',
          editable: true,
          type: structureTypes.TYPE_BAN_REVIEW_ASSIGNED_DROPDOWN,
        },
      ],
      [
        'notes',
        {
          header: 'Notes',
          editable: !isClosed,
        },
      ],
      [
        'outcome',
        {
          header: 'Outcome',
          editable: !isClosed,
          type: structureTypes.TYPE_CONDUCT_REVIEW_OUTCOME_DROPDOWN,
        },
      ],
      isClosed && [
        'ban.banner.full_name',
        {
          header: 'Banned by',
        },
      ],
      isClosed && [
        'ban.banned_at',
        {
          header: 'Ban date',
          type: structureTypes.TYPE_DATE,
        },
      ],
    ].filter(Boolean),
  },
  {
    title: 'Worker information',
    structure: [
      [
        'worker.salesforce_url',
        {
          header: 'Salesforce',
          type: structureTypes.TYPE_LINK,
          parameters: {
            to: ({ worker }: Partial<ConductReviewEntity>) => worker && worker.salesforce_url,
            target: '_blank',
            external: true,
          },
        },
      ],
      [
        'worker.id',
        {
          header: 'Worker ID',
          type: structureTypes.TYPE_LINK,
          parameters: {
            to: ({ worker }: Partial<ConductReviewEntity>) => worker && `/entity/workers/view/${worker.id}`,
          },
        },
      ],
      [
        'worker.email',
        {
          header: 'Appeals',
          type: structureTypes.TYPE_LINK,
          parameters: {
            to: ({ worker }: Partial<ConductReviewEntity>) =>
              worker && `/conduct/appeals/list?email=${worker.email}`,
          },
        },
      ],
      [
        'worker.full_name',
        {
          header: 'Name',
        },
      ],
      [
        'federated_state_code',
        {
          header: 'State',
          showForCountries: [SUPPORTED_COUNTRY_CODES.US],
        },
      ],
      [
        'worker.id',
        {
          header: 'Flex Levels',
          type: structureTypes.TYPE_LINK,
          parameters: {
            external: true,
            to: ({ worker }: Partial<ConductReviewEntity>) =>
              worker &&
              `https://flex-redash.indeed.tech/queries/24494?p_Worker%20ID=$${worker?.id}${country}`,
          },
          calc: () => {
            return 'Details'
          },
        },
      ],
    ].filter(Boolean),
  },
]

import { select, put, takeLatest } from 'redux-saga/effects'
import { get, map } from 'lodash-es'

import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { getShiftBookings } from 'syft-acp-core/api/resources/admin/shiftsBookings'
import * as types from 'syft-acp-core/actions/action-types'
import { checkItems, clearItems } from 'syft-acp-core/store/checkboxes/actions'

import { fetchShiftBookings, updateShiftBookingsDisplayed, clearShiftBookings } from './actions'
import { getShiftBookingsEntityMap } from './selectors'

export default function* root() {
  yield takeLatestApiCall('SHIFT_BOOKINGS_FETCH', getShiftBookings)

  yield takeLatest(types.SHIFTS_FETCH_SUCCEEDED, function* ({ payload, options }) {
    if (!options.exclude_bookings || options.exclude_bookings === 'false') {
      const shiftIds = payload.map(shift => shift.id)
      if (shiftIds.length) {
        yield put(
          checkItems(
            payload.map(shift => shift.id),
            'ShiftShowBookings',
            true,
          ),
        )
      }
    }

    if (options.exclude_bookings && options.exclude_bookings !== 'false') {
      yield put(clearItems('ShiftShowBookings'))
    }
  })

  yield takeLatest(types.CHECKBOXES_CHECK_ITEM, function* ({ scope, items }) {
    if (scope === 'ShiftShowBookings') {
      yield put(fetchShiftBookings({ options: { shiftIds: items } }))
    }
  })

  yield takeLatest(types.SHIFT_BOOKINGS_FETCH_SUCCEEDED, function* () {
    const shiftShowBookingsChecked = yield select(state =>
      get(state, 'checkboxes.items.ShiftShowBookings', {}),
    )
    const shiftBookings = yield select(getShiftBookingsEntityMap)

    const payload = map(shiftShowBookingsChecked, shiftId => ({
      ...shiftBookings[shiftId],
    }))

    yield put(updateShiftBookingsDisplayed({ payload }))
  })

  yield takeLatest(types.CHECKBOXES_UNCHECK_ITEM, function* ({ scope, items }) {
    if (scope === 'ShiftShowBookings') {
      yield put(clearShiftBookings({ id: items[0] }))
    }
  })
}

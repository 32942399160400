import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ControlLabel, Row, Col, FormGroup } from 'react-bootstrap'
import { get } from 'lodash-es'

import { selectListingByJobId$ } from 'syft-acp-core/selectors/getListingByJobId'
import { getErrors } from 'syft-acp-core/lib/notifications'
import DataText from 'syft-acp-core/components/EditableTable/DataText'
import DataEnum from 'syft-acp-core/components/EditableTable/DataEnum'
import DataRate from 'syft-acp-core/components/EditableTable/DataRate'
import DataDatetime from 'syft-acp-core/components/EditableTable/DataDatetime'
import { reqDefaults } from 'syft-acp-core/api/call'
import { selectShiftSimple$ } from 'syft-acp-core/store/listing-shifts/selectors'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { apiURL } from 'syft-acp-core/api/endpoints'
import DataTextarea from 'syft-acp-core/components/EditableTable/DataTextarea'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'
import { notify } from 'syft-acp-core/actions/notifications'

import './DuplicateShiftModal.css'

const duplicateReasons = [
  {
    value: 0,
    category: 'overbook',
    label: 'Overbook',
    message:
      'THIS IS A PAID BACKUP SHIFT. Please inform the client or the check in representative that you are a Paid Backup. If you arrive at the shift on time and you are not needed, you will be compensated 4 hours for the shift. ',
  },
  {
    value: 1,
    category: 'check_in',
    label: 'Check in',
    message:
      'CHECK IN SHIFT. Please ensure you arrive on time. The check in list will be sent in advance by email. You must have the google sheets app to access it.',
    // Modify start time to "start time - 30 mins"
    modifyStartTime: true,
  },
  {
    value: 2,
    category: 'no_show',
    label: 'No show',
    message: 'If you can arrive any earlier, please do.',
  },
  {
    value: 3,
    label: 'CI/OB',
    category: 'check_in_overbook',
    message:
      'To CHECK IN workers and act as an OVERBOOK if necessary. If you are not needed to work as an overbook, you will be compensated 4 hours for the shift. If you work the shift, the clock in time will not reflect the first 30 minutes for the check in, this will be paid separately and will reflect on your payslip. We will send you the check in list in advance by email. You must have the google sheets app to access it.',
    modifyStartTime: true,
  },
  {
    value: 4,
    label: 'Induction',
    category: 'induction',
    message:
      'This is an induction shift, once completed you will be able to book regular shifts with this client.',
  },
]

class DuplicateShiftModal extends PureComponent {
  static propTypes = {
    shiftID: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    callback: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    reloadData: PropTypes.func.isRequired,
    listingData: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.newShiftID = {
      listing_id: null,
      shift_id: null,
    }
    this.initialState = {
      isSendingData: false,
      valid: true,
      errorText: '',
      newShiftData: this.setupFormState(props),
    }
    this.state = this.initialState
  }

  componentDidUpdate(prevProps) {
    // If we are becoming visible, while not being visible, reset the form.
    if (this.props.item.isShown !== prevProps.item.isShown && this.props.item.isShown === true) {
      this.setDefaultState()
      this.addExistingShiftData(this.props)
    }
  }

  onConfirmHandler = async () => {
    const { reloadData, callback } = this.props
    const { isSendingData, newShiftData } = this.state
    const isValid = this.validate()

    if (!isValid || isSendingData) {
      return
    }

    const categoryData = newShiftData._duplicationCategory
      ? { category: newShiftData._duplicationCategory }
      : {}

    // Put together data to send to the API.
    const data = {
      workers_required: get(newShiftData, '_workersRequired', null),
      desc: [get(newShiftData, '_duplicateReasonText', ''), get(newShiftData, 'desc', '')].join(' \n').trim(),
      start_time: get(newShiftData, '_startTime', null),
      end_time: get(newShiftData, '_endTime', null),
      pay_rate: get(newShiftData, '_payRate', {}),
      uplifted_pay_rate: get(newShiftData, '_offerRate', {}),
      ...categoryData,
    }

    this.setState({ isSendingData: true, errorText: null })
    const result = await this.submitData(data)
    this.setState({ isSendingData: false })
    callback && callback(data)
    // If the result was successful, and we have a new listing/shift ID, reload its data.
    const { listing_id, shift_id, job_id } = this.newShiftID
    if (result && listing_id && shift_id) {
      reloadData(listing_id, shift_id, job_id, true, true)
    }
  }

  setValue = key => value => {
    this.validate(true, { [key]: value })
  }

  setDefaultState = () => this.setState(this.initialState)

  setDuplicateReason = _duplicateReason => {
    // When setting a check in shift, the start time needs to be altered.
    const dupeType = duplicateReasons[_duplicateReason]
    const _duplicationCategory = dupeType.category
    const startTime = this.startMinus30(dupeType.modifyStartTime)
    this.validate(true, {
      ...startTime,
      _duplicateReason,
      _duplicateReasonText: dupeType.message,
      _duplicationCategory,
    })
  }

  setDescription = value => {
    this.validate(true, { desc: value })
  }

  setupFormState = (props = this.props) => ({
    ...get(props, 'shiftData', {}),
    _duplicateReasonText: '',
    _duplicateReason: null,
    _payRate: get(props, 'shiftData.payRate', {}),
    _offerRate: get(props, 'shiftData._shift.offer_rate', {}),
    _startTime: get(props, 'shiftData._shift.start_time', null),
    _endTime: get(props, 'shiftData._shift.end_time', null),
    // Note: workers_required is always defaulted to 1.
    // The previous value is in 'shiftData._shift.job.workers_required'.
    _workersRequired: 1,
  })

  submitData = data =>
    new Promise(async resolve => {
      const { dispatch, shiftID } = this.props
      try {
        const req = new Request(apiURL(`/admin/shifts/${shiftID}/duplicate`), {
          ...reqDefaults('POST', 'application/json'),
          body: JSON.stringify(data),
        })
        const result = await fetch(req)
        const resultText = await result.text()
        const resultData = resultText ? JSON.parse(resultText) : {}
        const { listing_id, shift_id } = resultData
        this.newShiftID = listing_id ? resultData : this.newShiftID

        if (result.ok) {
          this.resetForm()
          this.closeModal()
          dispatch(
            notify('success', {
              title: 'Duplicated shift',
              message: (
                <>
                  The shift has been duplicated to ID <strong>{shift_id}</strong>. The new shift has been
                  opened.
                </>
              ),
            }),
          )
          return resolve(true)
        } else {
          // Successful POST, but something went wrong after all.
          const errorDetails = getErrors({ body: resultData }, true)
          dispatch(
            notify('error', {
              title: 'Could not duplicate shift',
              ...errorDetails,
            }),
          )
          this.setState({ errorText: errorDetails.message })
          return resolve(false)
        }
      } catch (err) {
        // Could not successfully POST.
        const message =
          'Something went wrong while sending data to the API. See the error page for more details.'
        dispatch(
          notify('error', {
            title: 'Could not duplicate shift',
            message,
            autoDismiss: 0,
          }),
        )
        this.setState({ errorText: message })
        return resolve(false)
      }
    })

  validate = (onlyTurnOff = false, newState = {}) => {
    const { valid } = this.state
    const currentData = { ...this.state.newShiftData, ...newState }
    const { desc, _startTime, _endTime } = currentData

    const dateNow = +new Date()
    const dateStart = +new Date(_startTime)
    const dateEnd = +new Date(_endTime)

    const invalidState = msg => ({ newShiftData: { ...currentData }, valid: false, errorText: msg })

    if (!onlyTurnOff || (onlyTurnOff && valid !== true)) {
      if (!desc) {
        this.setState(invalidState('No description has been entered.'))
        return false
      }
      if (dateStart > dateEnd) {
        this.setState(invalidState('The end date is before the start date.'))
        return false
      }
      if (dateStart < dateNow) {
        this.setState(invalidState('The start date is in the past.'))
        return false
      }
    }

    this.setState({ newShiftData: { ...currentData }, valid: true, errorText: '' })

    return true
  }

  closeModal = () => {
    this.props.reloadData()
    this.props.dispatch(hideModal(this.props.item.modalName))
  }

  openModal = () => this.props.dispatch(showModal(this.props.item.modalName))

  checkConfirm = ev => {
    if (ev.key === 'Enter') {
      this.onConfirmHandler()
    }
  }

  resetForm = () => {
    this.setState(this.initialState)
  }

  addExistingShiftData = (props = this.props) => {
    const { shiftData } = props
    if (shiftData && shiftData.shiftID > 0) {
      this.setState({ newShiftData: this.setupFormState(props) })
    }
  }

  // Returns the current shift's start time, minus 30 minutes.
  startMinus30 = (setToMinus30, props = this.props, state = this.state) => {
    const startTimeCurrStr = get(state, 'newShiftData._startTime', null)
    const startTimeOrigStr = get(props, 'shiftData._shift.start_time', null)
    if (!startTimeOrigStr) return {}

    const startTimeCurr = new Date(startTimeCurrStr)
    const startTimeOrig = new Date(startTimeOrigStr)
    const startTimeOrigMinus30 = new Date(startTimeOrigStr)
    startTimeOrigMinus30.setMinutes(startTimeOrigMinus30.getMinutes() - 30)

    // 1) If we're setting the modal to 'check in' shift, we should set the time to (start time - 30).
    // Or,
    // 2) If we're changing back from 'check in' shift to something else, we should instead restore
    // the original start time, *unless it has been altered.*
    const isMinus30 = startTimeCurr.toISOString() === startTimeOrigMinus30.toISOString()
    return {
      _startTime: setToMinus30
        ? startTimeOrigMinus30.toISOString() // 1
        : isMinus30
        ? startTimeOrig.toISOString()
        : startTimeCurrStr,
    }
  }

  render() {
    const { isSendingData, newShiftData, errorText } = this.state
    const { shiftID, listingData } = this.props
    const labelWidth = 3
    const fieldWidth = 9
    const isDisabled = isSendingData
    return (
      <Modal
        header="Duplicate shift"
        isShown={this.props.item.isShown}
        onClose={this.closeModal}
        onConfirm={this.onConfirmHandler}
        isLoading={isSendingData}
        errorText={errorText || null}
        footerText="All data not listed here will be copied from the original shift."
        confirmationText="Duplicate"
      >
        <div className="missing-time-modal">
          <FormGroup className="form-item" disabled={isSendingData}>
            <Row>
              <Col md={labelWidth}>
                <ControlLabel>Shift ID</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <span className="text-value">{shiftID}</span>
              </Col>
            </Row>
            <Row>
              <Col md={labelWidth}>
                <ControlLabel>Workers required</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <DataText
                  onChange={this.setValue('_workersRequired')}
                  value={String(newShiftData._workersRequired)}
                  type="number"
                  editable
                  disabled={isDisabled || get(newShiftData, '_workersRequired', null) == null}
                />
              </Col>
            </Row>
            <Row>
              <Col md={labelWidth}>
                <ControlLabel>Type of shift</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <DataEnum
                  editable
                  disabled={isDisabled}
                  options={duplicateReasons}
                  componentValue={newShiftData._duplicateReason}
                  onChange={this.setDuplicateReason}
                />
              </Col>
            </Row>
            <Row>
              <Col md={labelWidth}>
                <ControlLabel>Description</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <div className="textarea-top">
                  <DataTextarea
                    disabled={isDisabled}
                    value={newShiftData._duplicateReasonText}
                    onChange={this.setValue('_duplicateReasonText')}
                  />
                </div>
                <div className="textarea-bottom">
                  <DataTextarea
                    disabled={isDisabled}
                    value={newShiftData.desc}
                    onChange={this.setDescription}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={labelWidth}>
                <ControlLabel>Start time</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <DataDatetime
                  value={get(newShiftData, '_startTime', null)}
                  onChange={this.setValue('_startTime')}
                  disabled={isDisabled}
                  parameters={{ timeZone: get(listingData, 'venue.timezone') }}
                  editable
                  enabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={labelWidth}>
                <ControlLabel>End time</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <DataDatetime
                  value={get(newShiftData, '_endTime', null)}
                  disabled={isDisabled}
                  onChange={this.setValue('_endTime')}
                  parameters={{ timeZone: get(listingData, 'venue.timezone') }}
                  editable
                  enabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={labelWidth}>
                <ControlLabel>Client rate</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <DataRate
                  onChange={this.setValue('_payRate')}
                  value={get(newShiftData, '_payRate', {})}
                  editable
                  disabled={isDisabled}
                />
              </Col>
            </Row>
            <Row>
              <Col md={labelWidth}>
                <ControlLabel>Offer rate</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <DataRate
                  onChange={this.setValue('_offerRate')}
                  value={get(newShiftData, '_offerRate', {})}
                  editable
                  disabled={isDisabled}
                />
              </Col>
            </Row>
          </FormGroup>
        </div>
      </Modal>
    )
  }
}

export default connect((state, ownProps) => ({
  item: getModal$(state.modals)('duplicateShiftModal'),
  shiftData: selectShiftSimple$(state)(ownProps.shiftID),
  listingData: selectListingByJobId$(state)(ownProps.jobID),
}))(DuplicateShiftModal)

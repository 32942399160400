import { takeLatest } from 'redux-saga/effects'

import { notificationCall, redirectCall } from 'syft-acp-core/sagas/calls'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'

import { EmployerIDInput, EmployerVenueInput } from './types'
import * as types from '../action-types'
import * as api from './api'

export default function* root() {
  yield takeLatestApiCall('EMPLOYER_VENUES_FETCH', api.listEmployerVenues)
  yield takeLatestApiCall('EMPLOYER_VENUE_FETCH', api.fetchEmployerVenue)
  yield takeLatestApiCall('EMPLOYER_VENUE_SALES_WIN_FETCH', api.fetchEmployerVenueSalesWin)
  yield takeLatestApiCall('EMPLOYER_VENUE_SALES_WIN_SAVE', api.saveEmployerVenueSalesWin)
  yield takeLatestApiCall('EMPLOYER_VENUE_SAVE', api.saveEmployerVenue)
  yield takeLatestApiCall('EMPLOYER_VENUE_AREA_SAVE', api.saveEmployerVenueArea)
  yield takeLatestApiCall('EMPLOYER_VENUE_AREA_DELETE', api.deleteEmployerVenueArea)
  yield takeLatestApiCall('EMPLOYER_VENUE_DELETE', api.deleteEmployerVenue)
  yield takeLatest(
    [types.EMPLOYER_VENUE_SAVE_SUCCEEDED, types.EMPLOYER_VENUE_DELETE_SUCCEEDED],
    redirectCall,
    ({ request }: { request: EmployerIDInput }) => `/entity/employers/view/${request.employerID}/venues/list`,
  )
  yield takeLatest(
    [types.EMPLOYER_VENUE_AREA_SAVE_SUCCEEDED, types.EMPLOYER_VENUE_AREA_DELETE_SUCCEEDED],
    redirectCall,
    ({ request }: { request: EmployerVenueInput }) =>
      `/entity/employers/view/${request.employerID}/venues/view/${request.venueID}`,
  )
  yield takeLatest(
    [
      types.EMPLOYER_VENUE_AREA_SAVE_SUCCEEDED,
      types.EMPLOYER_VENUE_AREA_SAVE_FAILED,
      types.EMPLOYER_VENUE_AREA_DELETE_SUCCEEDED,
      types.EMPLOYER_VENUE_AREA_DELETE_FAILED,
      types.EMPLOYER_VENUE_SAVE_SUCCEEDED,
      types.EMPLOYER_VENUE_SAVE_FAILED,
      types.EMPLOYER_VENUE_DELETE_SUCCEEDED,
      types.EMPLOYER_VENUE_DELETE_FAILED,
      types.EMPLOYER_VENUES_FETCH_FAILED,
    ],
    notificationCall,
  )
}

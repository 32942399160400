import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormGroup } from 'react-bootstrap'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import { bindActionCreators } from 'redux'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { trackingEvents } from './Modal.tracking'
import PlatformsAutocomplete from 'syft-acp-core/components/EditableTable/PlatformsAutocomplete'
import { hideModal } from 'syft-acp-core/store/modals/actions'

import * as agencyPlatform from 'syft-acp-core/store/agency-platforms/actions'

export const AddAgencyEmployerModal = ({ actions, data, item }) => {
  const triggerEvent = useTrackingTrigger()
  const [platformID, setPlaftormID] = useState(null)

  const closeModal = useCallback(() => {
    actions.hideModal(item.modalName)
    setPlaftormID(null)
  }, [item, actions])

  const onConfirmHandler = useCallback(() => {
    actions.createAgencyPlatform({ agencyID: data.id, platformID })
    triggerEvent(trackingEvents.ADD_AGENCY_EMPLOYER.SUBMIT, {
      agency_id: data.id,
      employer_platform_id: platformID,
    })
    closeModal()
  }, [platformID, actions, closeModal, data, triggerEvent])

  const onChangePlatform = useCallback(
    value => {
      setPlaftormID(value[0])
    },
    [setPlaftormID],
  )

  return (
    <Modal
      header="Add new employer"
      isShown={item.isShown}
      canSubmit={platformID}
      onClose={closeModal}
      onConfirm={onConfirmHandler}
      confirmationText="Submit"
    >
      <FormGroup className="form-item">
        <PlatformsAutocomplete onChange={onChangePlatform} />
      </FormGroup>
    </Modal>
  )
}

AddAgencyEmployerModal.propTypes = {
  item: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
}

export default connect(
  state => ({
    item: getModal$(state.modals)('addAgencyEmployerModal'),
  }),
  dispatch => ({
    actions: bindActionCreators({ ...agencyPlatform, hideModal }, dispatch),
  }),
)(AddAgencyEmployerModal)

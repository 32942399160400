// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { NavItem } from 'react-bootstrap'

import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import {
  selectCurrentWorkerDuplicateAccountsCountTotal,
  selectWorkerDuplicateAccountsCountIsLoading,
} from 'syft-acp-core/store/worker-duplicate-accounts-count/selectors'
import { fetchWorkerDuplicateAccountsCount } from 'syft-acp-core/store/worker-duplicate-accounts-count/actions'
import LoadingSpinner from 'syft-acp-atoms/LoadingSpinner'
import SubNavigation, { SubLink } from 'syft-acp-atoms/SubNavigation'

export const storeConnector = connect(
  (state, { workerID }: { workerID: string | number }) => ({
    numDuplicateAccounts: selectCurrentWorkerDuplicateAccountsCountTotal(state, +workerID),
    isLoading: selectWorkerDuplicateAccountsCountIsLoading(state),
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchWorkerDuplicateAccountsCount }, dispatch),
  }),
)

type Props = ConnectedProps<typeof storeConnector> & {
  workerID: string | number
}

const WorkerSubNavigation = ({ workerID, numDuplicateAccounts, isLoading }: Props) => {
  const isNegativeAction = useFlexFlagIsOn('pte_34577_negative_actions')
  const workerNotes = useFlexFlagIsOn('onehost_acp_worker_notes')

  return (
    <SubNavigation>
      <SubLink to={`/entity/workers/view/${workerID}`} exact>
        Worker
      </SubLink>
      <SubLink to={`/entity/workers/view/${workerID}/interviews/list`}>Interviews</SubLink>
      {isNegativeAction ? (
        <SubLink to={`/entity/workers/view/${workerID}/negative-actions`}>Levels Negative Actions</SubLink>
      ) : (
        <SubLink to={`/entity/workers/view/${workerID}/strikes`}>Strikes</SubLink>
      )}
      <SubLink to={`/entity/workers/view/${workerID}/networks`}>Networks</SubLink>
      <SubLink to={`/entity/workers/view/${workerID}/compliance`}>Compliance</SubLink>
      <SubLink to={`/entity/workers/view/${workerID}/transfer-details`}>Transfer details</SubLink>
      {!!numDuplicateAccounts ? (
        <SubLink to={`/o/a/entity/workers/view/${workerID}/duplicate-accounts/list`}>
          Duplicate accounts
        </SubLink>
      ) : (
        <NavItem style={{ marginBlockStart: '-8px' }}>
          <LoadingSpinner isLoading={isLoading} />
        </NavItem>
      )}
      {workerNotes && <SubLink to={`/o/a/entity/workers/view/${workerID}/notes`}>Notes</SubLink>}
    </SubNavigation>
  )
}

export default storeConnector(WorkerSubNavigation)

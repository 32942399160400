import { takeLatest } from 'redux-saga/effects'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'

import * as types from './action-types'
import { createAgencyPlatform } from './api'

export default function* saga() {
  yield takeLatestApiCall(types.CREATE_AGENCY_PLATFORM_POST_BEGIN, createAgencyPlatform)
  yield takeLatest(
    [types.CREATE_AGENCY_PLATFORM_POST_FAILED, types.CREATE_AGENCY_PLATFORM_POST_SUCCEEDED],
    notificationCall,
  )
}
